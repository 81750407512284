import React, { useContext } from 'react'

import Footer from 'components/Layout/Footer'
import { TranslationContext } from 'context/TranslationContext'
import PricingTableDe from './PricingTableDE'
import PricingTableEN from './PricingTableEN'


const PricingTable = () => {

  const { translations: { locale } } = useContext(TranslationContext)

  return (
    <div className='m-generalTerms'>
      {locale === 'de' ? <PricingTableDe /> : <PricingTableEN />}
        <Footer />
    </div>

  )
}

export default PricingTable