import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { logo } from 'constants/images'

const ImprintDE = () => {
  return (
    <Fragment>
      <Link to="/"><img src={logo} alt="logo"/></Link>
      <div className='m-imprint__parts'>
        <span>roadX AG</span>
        <p>Bellerivematte 10</p>
        <p>CH-6006 Luzern</p>
        <p>Schweiz</p>
      </div>
      <div className='m-imprint__parts'>
        <span>Vertretungsberechtigter:</span>
        <p>Mitglied des Verwaltungsrates (CEO/Geschäftsführer): Oliver Wegner</p>
      </div>
      <div className='m-imprint__parts'>
        <span>Unternehmens-Identifikationsnummer (UID)</span>
        <p>CHE-296.789.097</p>
      </div>
      <div className='m-imprint__parts'>
        <span>Handelsregisteramt:</span>
        <p>Handelsregisteramt des Kantons Luzern</p>
        <p>Bundesplatz 14</p>
        <p>CH-6002 Luzern</p>
      </div>
      <div className='m-imprint__parts'>
        <span>Mehrwertsteuer-Nummer</span>
        <p>CHE-296.789.097 MWST</p>
      </div>
      <div className='m-imprint__parts'>
        <span>Internetadressen:</span>
        <p><a href='https://www.roadx.tech' target='_blank' >www.roadx.tech</a>, <a href='https://www.roadx.app' target='_blank'>www.roadx.app</a>, <a href='https://www.roadx.ch' target='_blank'>www.roadx.ch</a></p>
      </div>
      <div className='m-imprint__parts'>
        
        <p><span>E-Mail:</span> info@roadx.tech</p>
      </div>
    </Fragment>
  )
}

export default ImprintDE