const USER_TOKEN = 'evolution_user_token'
const USER_ID = 'evolution_user_id'
const USER_EMAIL = 'evolution_user_email'
const USER_LANGUAGE = 'evolution_user_language_code'
const USER_ADVANCED_FILTERS = 'evolution_advanced_filters'


export const getUserToken = () => {
    return localStorage.getItem(USER_TOKEN)
}

export const setUserToken = token => {
    localStorage.setItem(USER_TOKEN, token)
}

export const deleteUserToken = () => {
    localStorage.removeItem(USER_TOKEN)
}

export const getUserId = () => {
    return Number(localStorage.getItem(USER_ID))
}

export const setUserId = id => {
    localStorage.setItem(USER_ID, id)
}

export const deleteUserId = () => {
    localStorage.removeItem(USER_ID)
}

export const setUserEmail = email => {
    localStorage.setItem(USER_EMAIL, email)
}

export const deleteUserEmail = () => {
    localStorage.removeItem(USER_EMAIL)
}

export const getUserLanguage = () => {
    return localStorage.getItem(USER_LANGUAGE)
}

export const setUserLanguage = code => {
    localStorage.setItem(USER_LANGUAGE, code)
}

export const getUserAdvancedFilters = () => {
    return localStorage.getItem(USER_ADVANCED_FILTERS)
}

export const setUserAdvancedFilters = value => {
    localStorage.setItem(USER_ADVANCED_FILTERS, value)
}

export const deleteUserAdvancedFilters = () => {
    localStorage.removeItem(USER_ADVANCED_FILTERS)
}

export const clearStorage = () => {
    deleteUserToken()
    deleteUserId()
    deleteUserEmail()
    deleteUserAdvancedFilters()
}