import React from 'react';
import PropTypes from 'prop-types';
import SearchGlobal from './SearchGlobal';
import SavedLinks from './SavedLinks';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import { logo } from 'constants/images';

const Header = ({ searchParam, setSearchParam, setOpenSidebar, hamBtnRef, openSidebar }) => {
  return (
    <div className="m-topnav">
      <div className="m-topnav__logo">
        <Link to="/"><img src={logo} alt="logo" /></Link>
      </div>
      <div className='m-topnav__search'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <button
          ref={hamBtnRef}
          className="a-btn -primary -hamburger"
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          <Icon name={'menu'} color="#FFF" />
        </button>
        <SearchGlobal
          searchParam={searchParam}
          setSearchParam={setSearchParam}
        />
      </div>
      <SavedLinks />
    </div>
  );
};

Header.propTypes = {
  searchParam: PropTypes.string.isRequired,
  setSearchParam: PropTypes.func.isRequired,
  openSidebar: PropTypes.bool.isRequired
};

export default Header;
