import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslate } from 'react-polyglot'

import ENTITY from 'constants/entities'
import ROUTES from 'constants/routes'

import { getEntityService } from 'services/entity.service'

import { AlertContext } from 'context/AlertContext'

import { BASE_URL } from 'utils/axiosClient'

import Loader from 'components/Loader'
import Icon from 'components/Icon'
import { ALERT_TYPES } from 'components/Alert'
import { CurrentUserContext } from 'context/CurrentUserContext'

const GlobalSearchList = ({ searchParam }) => {
    const t = useTranslate()
    const history = useHistory()

    const { setAlert } = useContext(AlertContext)
    const { isFreemiumUser } = useContext(CurrentUserContext)

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        const getCompanies = async () => {
            try {
                setIsLoading(true)
                const searchedData = await getEntityService(ENTITY.COMPANY_PROFILE, { search: searchParam })
                setData(searchedData)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                if (error?.response?.status !== 403) {
                    setAlert(error, ALERT_TYPES.ERROR)
                }
            }
        }
        getCompanies()
    }, [searchParam])

    const handleShow = id => {
        history.push(`${ROUTES.COMPANIES}${ROUTES.COMPANY_PROFILE}/${id}`)
    }

    const handleViewMore = () => {
        history.push(`${ROUTES.COMPANIES}?Keyword=[${searchParam}]`)
    }

    // const getWebsite = website => {
    //     if (website) {
    //         if (website.includes('https://')) return website
    //         return `https://${website} |`
    //     }
    //     return ''
    // }

    if (isLoading) return <Loader />

    return (
        <div className='m-globalSearchList'>
            <div className='m-globalSearchList__wrapper'>
                {/*<div className='m-globalSearchList__cantFind'>*/}
                {/*    <span>{t('globalSearch.cantFind')}</span>*/}
                {/*    <Button btnClass={BUTTON_TYPES.PRIMARY} label='Add a company' />*/}
                {/*</div>*/}
                <div className='m-globalSearchList__container'>
                    <div className='m-globalSearchList__block'>
                        <span className='m-globalSearchList__type'>{t('globalSearch.companies')}</span>
                        <div className='m-globalSearchList__rows'>
                            {data.map(({ id, name, website, status, legalForm, companyLogo }) => (
                                <div className='m-globalSearchList__row' key={id} onClick={() => handleShow(id)}>
                                    {companyLogo ? 
                                        <img src={`${BASE_URL}/${companyLogo}`} alt='itemImg' /> : 
                                        <span className="image"><div><Icon name={'companies'} size={16} color='#B4B9C4' /></div></span>
                                    }
                                    <div className='m-globalSearchList__rowContent'>
                                        <span className='m-globalSearchList__rowName'>{name}</span>
                                        {!isFreemiumUser && <span className='m-globalSearchList__rowInfo'>
                                            {`${website ? `${website} |` : ''} ${legalForm ? `${legalForm} |` : ''} ${status ? status : ''}`}
                                        </span>}
                                    </div>
                                </div>
                            ))}
                            <div className='m-globalSearchList__more' onClick={handleViewMore}>{t('globalSearch.viewMore')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlobalSearchList
