// English by default, this must be synced with BE
export const DEFAULT_LANGUAGE = 'en'

export const COMPARE_SOURCES = {
    SOURCE_DEAL_PROCESS: 1,
    SOURCE_SAVED_COMPARED_COMPANIES: 2,
    SOURCE_OWN_PORTFOLIO: 3,
    SOURCE_SIMILAR_COMPANIES: 4
}

export const USER_STATISTICS_RESOURCES = {
    RESOURCE_SEARCH_PROFILE_MATCHES: 1,
    RESOURCE_FAVOURITE_COMPANIES: 2,
    RESOURCE_PLATFORM: 3
}

export const EQUINTEL_CHARTS = {
    DATA_SOURCE_TYPES: 'data_source_types',
    TOP_DOMAINS: 'top_domains',
    QUANTITY_OVER_TIME: 'quantity_over_time',
    PRODUCT_FREQUENCIES: 'product_frequencies',
    APPLICATION_FREQUENCIES: 'aplication_frequencies',
    TECHNOLOGY_FREQUENCIES: 'technology_frequencies',
    COMPANY_FREQUENCIES: 'company_frequencies',
    PRODUCT_OVERVIEW: 'product_overview',
    HEATMAPS_PEER_APPLICATION: 'heatmaps_peer_applications',
    HEATMAPS_PEER_TECHNOLOGIES: 'heatmaps_peer_technologies',
    HEATMAPS_PRODUCT_TECHNOLOGIES: 'heatmaps_product_technologies',
    HEATMAPS_PRODUCT_INDUSTRIES: 'heatmaps_product_industries'
}

//TODO:: Sync this with backend
export const ROLES = {
    ADMIN: {
        ID: 1,
        NAME: 'ROLE_ADMIN'
    },
    INVESTOR: {
        ID: 2,
        NAME: 'ROLE_INVESTOR'
    },
    ENTREPRENEUR: {
        ID: 3,
        NAME: 'ROLE_ENTREPRENEUR'
    },
    CLICK_WORKER: {
        ID: 4,
        NAME: 'ROLE_CLICK_WORKER'
    }
}

// TODO:: Sync this with backend
// See with backend if there will be flag for NOT_INTERESTING
export const SELECTION_PROCESS_OPTIONS = {
    INTERESTING: {
        name: "Interesting" 
    }
};

export const FILE_TYPES = {
    IMAGE: "image",
    FILE: "file"
};

export const SORT_TYPES = {
    ASC: 1,
    DESC: -1
}

export const NOTIFICATION_TYPES = {
    ADMIN_NOTICE: 'Admin adds the company to search',
    COMPANY_DATA: 'Company data has changes',
    COMPANY_SOLD: 'Company is sold'
}

export const COMPANY_HEADQUARTERS_TYPES = {
    SAVED_COMPANIES: 1,
    SEARCH_PROFILE_MATCHES: 2
}

export const SUBSCRIPTION_STATUSES = {
    PENDING: {
        ID: 0,
        NAME: 'Pending'
    },
    ACTIVE: {
        ID: 1,
        NAME: 'Active'
    },
    EXPIRED: {
        ID: 2,
        NAME: 'Expired'
    }
}

export const SUBSCRIPTION_STATUS_ITEMS = [
    {
        id: SUBSCRIPTION_STATUSES.PENDING.ID,
        name: SUBSCRIPTION_STATUSES.PENDING.NAME
    },
    {
        id: SUBSCRIPTION_STATUSES.ACTIVE.ID,
        name: SUBSCRIPTION_STATUSES.ACTIVE.NAME
    },
    {
        id: SUBSCRIPTION_STATUSES.EXPIRED.ID,
        name: SUBSCRIPTION_STATUSES.EXPIRED.NAME
    }
]

export const INVITATIONS_STATUSES = {
    PENDING: {
        ID: 0,
        NAME: 'Pending'
    },
    ACCEPTED: {
        ID: 1,
        NAME: 'Accepted'
    },
    REJECTED: {
        ID: 2,
        NAME: 'Rejected'
    }
}
