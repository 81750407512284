import React from 'react'
import { Route } from "react-router-dom";

const PropsRoute = ({
    path,
    component,
    exact,
    ...params
}) => {

    return (
        <Route
            path={path}
            exact={exact}
            render={props => (
                React.createElement(component, {...props, ...params})
            )}
        />
    )
}

export default PropsRoute
