import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import { AlertContext } from 'context/AlertContext'
import axios, { GET } from '../utils/axiosClient'
import { formatResponseData, formatUrl } from '../utils/jsonApiFormatters'
import useQueryParams from './useQueryParams'
import { ALERT_TYPES } from 'components/Alert'
import ROUTES from 'constants/routes'

const useFetchData = (entity, params = {}, reload, isJSONAPI = true, condition = true) => {

    const [response, setResponse] = useState({ data: [], meta: null })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const history = useHistory()
    const { setAlert } = useContext(AlertContext)

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                let PATH = `/${entity}`
                const url = formatUrl(PATH, params)
                const response = await axios(GET, url)
                if (!isJSONAPI) {
                    setResponse(response.data)
                } else {
                    const { data, meta } = formatResponseData(response.data)
                    setResponse({ data, meta })
                }
                setIsLoading(false)
                setError(null)
            } catch (error) {
                setResponse({ data: [], meta: null })
                setIsLoading(false)
                setError(error)
                if (error?.response?.status !== 403) {
                    setAlert(error, ALERT_TYPES.ERROR)
                }
            }
        }
        if (entity && condition) {
            getData()
        }
    }, [reload])

    return {
        response,
        isLoading,
        error
    }
}

export default useFetchData