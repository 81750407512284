import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Icon from 'components/Icon'

import { secondaryColor } from 'constants/colors'

const SearchGlobal = ({ searchParam, setSearchParam }) => {
    const t = useTranslate()
    const history = useHistory()

    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        setSearchParam('')
        setSearchValue('')
    }, [history.location])

    const handleChangeDebounce = _.debounce((event) => {
        const { value } = event.target
        const encodedValue = encodeURIComponent(value)
        setSearchParam(encodedValue)
    }, 1000)

    const handleChange = (event) => {
        const { value } = event.target
        setSearchValue(value)

        handleChangeDebounce(event)
    }

    const handleClear = () => {
        setSearchParam('')
        setSearchValue('')
    }

    return (
        <div>
            <div className='m-searchGlobal'>
                <input
                    type='text'
                    placeholder={`${t('form.typeToSearch')}...`}
                    value={searchValue}
                    onChange={handleChange}
                    className='m-searchGlobal__input'
                />
                <Icon name='search' color={secondaryColor} />
                {searchParam && <span onClick={handleClear} className='m-searchGlobal__close' />}
            </div>
        </div>
    )
}

SearchGlobal.propTypes = {
    searchParam: PropTypes.string.isRequired,
    setSearchParam: PropTypes.func.isRequired
}

export default SearchGlobal
