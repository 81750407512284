import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'context/AlertContext'
import Icon from 'components/Icon'

export const ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    DEFAULT: 'default'
}

export const ALERT_POSITIONS = {
    CENTER: 'center',
    RIGHT: 'right'
}

const Alert = () => {
    const t = useTranslate()
    const { alert, type, position, setAlert } = useContext(AlertContext)

    if (!alert) return null

    return (
        <span className={`a-alert -${type} -${position}`} onClick={() => setAlert('')}>
            {type === ALERT_TYPES.DEFAULT && <Icon name='smile' color='#fff' size={24} />}
            {t(alert)}
        </span>
    )
}

export default Alert
