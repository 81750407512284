import React, { useState, useRef, useEffect } from 'react';
import GlobalSearchList from '../GlobalSearchList';
import Header from './Header';
import Sidebar from './Sidebar';
import useWindowsDimensions from 'hooks/useWindowDimension'

const Layout = ({ children }) => {

  const {width} = useWindowsDimensions()
  const sidbarStatus = width < 768 ? false : true
  const [openSidebar, setOpenSidebar] = useState(sidbarStatus);
  const [globalSearchParam, setGlobalSearchParam] = useState('');
  const hocRef = useRef(null)
  const hamBtnRef = useRef(null)


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleClickOutside = (event) => {
      if (document.body.clientWidth < 768 && hocRef && hocRef.current && !hocRef.current.contains(event.target) && !hamBtnRef.current.contains(event.target)) {
        setOpenSidebar(false);
      }
    }

  return (
    <div>
      <Sidebar open={openSidebar} setOpen={setOpenSidebar} hocRef={hocRef} />
      <Header
        searchParam={globalSearchParam}
        setOpenSidebar={setOpenSidebar}
        setSearchParam={setGlobalSearchParam}
        hamBtnRef={hamBtnRef}
        openSidebar={openSidebar}
      />
      <div className={`m-main ${openSidebar ? '-sidenavOpen' : ''}`}>
        <div className="m-main__wrapper">
          <div className="_wr ">
            <div className="_w">
              <div className="m-main__content">
                {!!globalSearchParam ? (
                  <GlobalSearchList searchParam={globalSearchParam} />
                ) : (
                  children
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
