import React, { useContext } from 'react'

import { TranslationContext } from 'context/TranslationContext';

import DataProtectionDE from './DataProtectionDE';
import DataProtectionEN from './DataProtectionEN';
import FAQ from 'screens/noAuth/FAQ';
import Footer from 'components/Layout/Footer';

const DataProtection = () => {

    const { translations: { locale } } = useContext(TranslationContext)

    return (
        <div className='m-generalTerms'>
            {locale === 'de' ? <DataProtectionDE /> : <DataProtectionEN />}
            <FAQ/>
            <Footer />
        </div>
    )
}

export default DataProtection
