import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { TranslationContext } from 'context/TranslationContext'

import useFetchData from 'hooks/useFetchData'
import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import ENTITY from 'constants/entities'

import EnFlag from '../../../assets/images/united-kingdom-flag-icon-32.png'
import DeFlag from '../../../assets/images/germany-flag-icon-32.png'

const renderFlag = (code) => {
    switch (code) {
        case 'en':
            return EnFlag
        case 'de': 
            return DeFlag
        default:
            break;
    }
}

const LanguagePicker = ({ open }) => {

    const t = useTranslate()
    const { translations, setTranslations } = useContext(TranslationContext)

    const { response: { data }, isLoading } = useFetchData(ENTITY.LANGUAGE, { pagination: false })

    if (isLoading) return null;

    const currentLanguage = data.find(language => language.code === translations.locale)

    if (!currentLanguage) return null;

    return (
        <div className='m-nav__languagePicker'>
            <div>
                <span className='m-nav__languagePickerContainer'>{currentLanguage.code} <span className={`a-chevron ${open ? '-up' :  '-down'}`} /> </span>
            </div>
            <ul className={`m-nav__languagePickerPopup ${open ? '-open' : ''}`}>
                {data.map(language => (
                    <li
                        key={language.id}
                        onClick={() => setTranslations(language)} >
                        {language.name}
                        {/* {t(`${language.entityType}.${language.id}.name`)} */}
                        <img className='flagIcon' src={renderFlag(language.code)} alt='flag'/>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default clickOutsideHOC(LanguagePicker)
