import axios, { DELETE, GET, PATCH, POST } from 'utils/axiosClient'
import { formatRequestData, formatResponseData, formatUrl } from 'utils/jsonApiFormatters'

export const getEntityService = async (entity, params, isJSONAPI = true) => {
    try {
        const PATH = `/${entity}`
        const url = formatUrl(PATH, params)
        const response = await axios(GET, url)
        if (!isJSONAPI) {
            return response.data
        } else {
            const { data } = formatResponseData(response.data)
            return data
        }
    } catch (error) {
        throw error
    }
}

export const getEntityByIdService = async (entity, id, params) => {
    try {
        const PATH = `/${entity}/${id}`
        const url = formatUrl(PATH, params)
        const response = await axios(GET, url)
        const { data } = formatResponseData(response.data)
        return data
    } catch (error) {
        throw error
    }
}

export const createEntityService = async (entity, formData, isJSONAPI = true) => {
    try {
        const preparedData = isJSONAPI ? formatRequestData(entity, formData) : formData
        const response = await axios(POST, `/${entity}`, preparedData)
        if (!isJSONAPI) {
            return response
        }
        const { data, meta } = formatResponseData(response.data)
        return { data, meta }
    } catch (error) {
        throw error
    }
}

export const editEntityService = async (entity, id, formData) => {
    try {
        const preparedData = formatRequestData(entity, formData)
        const response = await axios(PATCH, `/${entity}/${id}`, preparedData)
        const { data, meta } = formatResponseData(response.data)
        return { data, meta }
    } catch (error) {
        throw error
    }
}

export const deleteEntityService = async (entity, id) => {
    try {
        await axios(DELETE, `/${entity}/${id}`)
    } catch (error) {
        throw error
    }
}

export const deleteAllEntityService = async (entity, params) => {
    try {
        const url = formatUrl(`/${entity}`, params)
        await axios(POST, url)
    } catch (error) {
        throw error
    }
}