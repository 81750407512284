import React from 'react';
import { PropTypes } from 'prop-types';

import { secondaryColor } from 'constants/colors';

export const ICON_SIZE = {
  LARGE: 24,
  SMALL: 18,
};

const getViewBoxSize = (size) => {
  switch (size) {
    case ICON_SIZE.LARGE:
    case ICON_SIZE.SMALL:
    default:
      return 24;
  }
};

const renderIcon = (name, color, size, fill) => {
  const boxSize = getViewBoxSize(size);
  switch (name) {
    case 'dashboard':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-gauge"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <circle cx="12" cy="12" r="1" />
          <line x1="13.41" y1="10.59" x2="16" y2="8" />
          <path d="M7 12a5 5 0 0 1 5 -5" />
        </svg>
      );
    case 'news':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-news"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
          <line x1="8" y1="8" x2="12" y2="8" />
          <line x1="8" y1="12" x2="12" y2="12" />
          <line x1="8" y1="16" x2="12" y2="16" />
        </svg>
      );
    case 'route':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-route"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="6" cy="19" r="2" />
          <circle cx="18" cy="5" r="2" />
          <path d="M12 19h4.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h3.5" />
        </svg>
      );
    case 'companies':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-building-skyscraper"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="3" y1="21" x2="21" y2="21" />
          <path d="M5 21v-14l8 -4v18" />
          <path d="M19 21v-10l-6 -4" />
          <line x1="9" y1="9" x2="9" y2="9.01" />
          <line x1="9" y1="12" x2="9" y2="12.01" />
          <line x1="9" y1="15" x2="9" y2="15.01" />
          <line x1="9" y1="18" x2="9" y2="18.01" />
        </svg>
      );
    case 'chart-radar':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-chart-radar"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 3l9.5 7l-3.5 11h-12l-3.5 -11z" />
          <path d="M12 7.5l5.5 4l-2.5 5.5h-6.5l-2 -5.5z" />
          <path d="M2.5 10l9.5 3l9.5 -3" />
          <path d="M12 3v10l6 8" />
          <path d="M6 21l6 -8" />
        </svg>
      );
    case 'jump-rope':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-jump-rope"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M6 14v-6a3 3 0 1 1 6 0v8a3 3 0 0 0 6 0v-6" />
          <rect x="16" y="3" width="4" height="7" rx="2" />
          <rect x="4" y="14" width="4" height="7" rx="2" />
        </svg>
      );
    case 'users':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-users"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="9" cy="7" r="4" />
          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
        </svg>
      );
    case 'compare':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-git-compare"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="6" cy="6" r="2" />
          <circle cx="18" cy="18" r="2" />
          <path d="M11 6h5a2 2 0 0 1 2 2v8" />
          <polyline points="14 9 11 6 14 3" />
          <path d="M13 18h-5a2 2 0 0 1 -2 -2v-8" />
          <polyline points="10 15 13 18 10 21" />
        </svg>
      );
    case 'briefcase':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-briefcase"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="3" y="7" width="18" height="13" rx="2" />
          <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
          <line x1="12" y1="12" x2="12" y2="12.01" />
          <path d="M3 13a20 20 0 0 0 18 0" />
        </svg>
      );
    case 'settings':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-settings"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
          <circle cx="12" cy="12" r="3" />
        </svg>
      );
    case 'user':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-user"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="7" r="4" />
          <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
        </svg>
      );
    case 'card-id':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-id"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="3" y="4" width="18" height="16" rx="3" />
          <circle cx="9" cy="10" r="2" />
          <line x1="15" y1="8" x2="17" y2="8" />
          <line x1="15" y1="12" x2="17" y2="12" />
          <line x1="7" y1="16" x2="17" y2="16" />
        </svg>
      );
    case 'building':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-building"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="3" y1="21" x2="21" y2="21" />
          <line x1="9" y1="8" x2="10" y2="8" />
          <line x1="9" y1="12" x2="10" y2="12" />
          <line x1="9" y1="16" x2="10" y2="16" />
          <line x1="14" y1="8" x2="15" y2="8" />
          <line x1="14" y1="12" x2="15" y2="12" />
          <line x1="14" y1="16" x2="15" y2="16" />
          <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
        </svg>
      );
    case 'email':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-at"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="4" />
          <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
        </svg>
      );
    case 'search':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-search"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="10" cy="10" r="7" />
          <line x1="21" y1="21" x2="15" y2="15" />
        </svg>
      );
    case 'star':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-star"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
        </svg>
      );
    case 'heart':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-heart"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
        </svg>
      );
    case 'instagram':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-brand-instagram"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="16" height="16" rx="4" />
          <circle cx="12" cy="12" r="3" />
          <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
        </svg>
      );
    case 'facebook':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-brand-facebook"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
        </svg>
      );
    case 'linkedin':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-brand-linkedin"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="16" height="16" rx="2" />
          <line x1="8" y1="11" x2="8" y2="16" />
          <line x1="8" y1="8" x2="8" y2="8.01" />
          <line x1="12" y1="16" x2="12" y2="11" />
          <path d="M16 16v-3a2 2 0 0 0 -4 0" />
        </svg>
      );
    case 'youtube':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-brand-youtube"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="3" y="5" width="18" height="14" rx="4" />
          <path d="M10 9l5 3l-5 3z" />
        </svg>
      );
    case 'lock':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-lock"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="5" y="11" width="14" height="10" rx="2" />
          <circle cx="12" cy="16" r="1" />
          <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
        </svg>
      );
    case 'eyeOn':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-eye"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="2" />
          <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
        </svg>
      );
    case 'eyeOff':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-eye-off"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="3" y1="3" x2="21" y2="21" />
          <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
          <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
        </svg>
      );
    case 'phone':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-device-mobile"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="7" y="4" width="10" height="16" rx="1" />
          <line x1="11" y1="5" x2="13" y2="5" />
          <line x1="12" y1="17" x2="12" y2="17.01" />
        </svg>
      );
    case 'filter':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-adjustments-horizontal"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="14" cy="6" r="2" />
          <line x1="4" y1="6" x2="12" y2="6" />
          <line x1="16" y1="6" x2="20" y2="6" />
          <circle cx="8" cy="12" r="2" />
          <line x1="4" y1="12" x2="6" y2="12" />
          <line x1="10" y1="12" x2="20" y2="12" />
          <circle cx="17" cy="18" r="2" />
          <line x1="4" y1="18" x2="15" y2="18" />
          <line x1="19" y1="18" x2="20" y2="18" />
        </svg>
      );
    case 'heart':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-heart"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
        </svg>
      );
    case 'note':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-note"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="13" y1="20" x2="20" y2="13" />
          <path d="M13 20v-6a1 1 0 0 1 1 -1h6v-7a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7" />
        </svg>
      );
    case 'map-pin':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-map-pin"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="11" r="3" />
          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
        </svg>
      );
    case 'world':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-world"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="3.6" y1="9" x2="20.4" y2="9" />
          <line x1="3.6" y1="15" x2="20.4" y2="15" />
          <path d="M11.5 3a17 17 0 0 0 0 18" />
          <path d="M12.5 3a17 17 0 0 1 0 18" />
        </svg>
      );
    case 'circle-check':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-circle-check"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <path d="M9 12l2 2l4 -4" />
        </svg>
      );
    case 'pencil':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-pencil"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
          <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
        </svg>
      );
    case 'trash':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-trash"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="4" y1="7" x2="20" y2="7" />
          <line x1="10" y1="11" x2="10" y2="17" />
          <line x1="14" y1="11" x2="14" y2="17" />
          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
        </svg>
      );
    case 'language':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-language"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 7h7m-2 -2v2a5 8 0 0 1 -5 8m1 -4a7 4 0 0 0 6.7 4" />
          <path d="M11 19l4 -9l4 9m-.9 -2h-6.2" />
        </svg>
      );
    case 'notes':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-notes"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="5" y="3" width="14" height="18" rx="2" />
          <line x1="9" y1="7" x2="15" y2="7" />
          <line x1="9" y1="11" x2="15" y2="11" />
          <line x1="9" y1="15" x2="13" y2="15" />
        </svg>
      );
    case 'chevron-left':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-chevron-left"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M15 6L9 12L15 18" stroke="currentColor" />
        </svg>
      );
    case 'chevron-right':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-chevron-right"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 6L15 12L9 18" stroke="currentColor" />
        </svg>
      );
    case 'smile':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-mood-smile"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <circle cx="12" cy="12" r="9" />
          <path d="M9 10h.01M15 10h.01M9.5 15a3.5 3.5 0 005 0" />
        </svg>
      );
    case 'barometer':
      return (
        <svg width="228" height="114" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path
              d="M216 114c0-56.333-45.667-102-102-102S12 57.667 12 114"
              stroke={secondaryColor}
              stroke-width="24"
              stroke-dasharray="1.600000023841858,5"
            />
            <path
              d="M187.486 112.9c0-40.87-33.13-74-74-74-40.869 0-74 33.13-74 74"
              stroke="#E0E7FF"
              stroke-width="2"
              stroke-dasharray="1,4.799999713897705"
            />
          </g>
        </svg>
      );
    case 'apps':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-apps"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="6" height="6" rx="1" />
          <rect x="4" y="14" width="6" height="6" rx="1" />
          <rect x="14" y="14" width="6" height="6" rx="1" />
          <line x1="14" y1="7" x2="20" y2="7" />
          <line x1="17" y1="4" x2="17" y2="10" />
        </svg>
      );
    case 'versions':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-versions"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="10" y="5" width="10" height="14" rx="2" />
          <line x1="7" y1="7" x2="7" y2="17" />
          <line x1="4" y1="8" x2="4" y2="16" />
        </svg>
      );
    case 'puzzle':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-puzzle"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" />
        </svg>
      );
    case 'message':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-message"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
          <line x1="8" y1="9" x2="16" y2="9" />
          <line x1="8" y1="13" x2="14" y2="13" />
        </svg>
      );
    case 'current-location':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-current-location"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="3" />
          <circle cx="12" cy="12" r="8" />
          <line x1="12" y1="2" x2="12" y2="4" />
          <line x1="12" y1="20" x2="12" y2="22" />
          <line x1="20" y1="12" x2="22" y2="12" />
          <line x1="2" y1="12" x2="4" y2="12" />
        </svg>
      );
    case 'triangle':
      return (
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="1376"
          height="603"
          className="triangle" 
          viewBox="0 0 1376 603"
        >
            <g data-name="Layer 2">
              <path d="M.5 602.38V.62L1373.66 301.5Z" style={{fill: '#e4e4eb', stroke: '#cbd8e6', strokeMiterlimit: '10'}} data-name="Layer 1"/>
            </g>
        </svg>
      );
    case 'percentage':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-percentage"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <circle cx="17" cy="17" r="1" />
          <circle cx="7" cy="7" r="1" />
          <path d="M6 18L18 6" />
        </svg>
      );
    case 'current-location':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-current-location"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <circle cx="12" cy="12" r="3" />
          <circle cx="12" cy="12" r="8" />
          <path d="M12 2v2M12 20v2M20 12h2M2 12h2" />
        </svg>
      );
    case 'calendar':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-calendar-event"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <rect x="4" y="5" width="16" height="16" rx="2" />
          <path d="M16 3v4M8 3v4M4 11h16M8 15h2v2H8z" />
        </svg>
      );
    case 'sort':
      return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path fill="none" d="M-130-99h1440v700H-130z" />
            <g stroke="#9C9FA9" strokeLinecap="round" strokeLinejoin="round">
              <path d="M1 .857h6M1 6h6M1 11.143h7.714M10.429 3.429L13" />
            </g>
          </g>
        </svg>
      );
    case 'sort-asc':
      return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path fill="none" d="M-130-99h1440v700H-130z" />
            <g stroke="#9C9FA9" strokeLinecap="round" strokeLinejoin="round">
              <path d="M1 .857h6M1 6h6M1 11.143h7.714M10.429 3.429L13 .857l2.571 2.572M13 .857v10.286" />
            </g>
          </g>
        </svg>
      );
    case 'sort-desc':
      return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path fill="none" d="M-214-99h1440v700H-214z" />
            <g stroke="#9C9FA9" strokeLinecap="round" strokeLinejoin="round">
              <path d="M1 .857h7.714M1 6h6M1 11.143h6M10.429 8.571L13 11.143l2.571-2.572M13 .857v10.286" />
              <g>
                <path d="M1 .857h7.714M1 6h6M1 11.143h6M10.429 8.571L13 11.143l2.571-2.572M13 .857v10.286" />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'box-view':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-layout-grid"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <rect x="4" y="4" width="6" height="6" rx="1" />
          <rect x="14" y="4" width="6" height="6" rx="1" />
          <rect x="4" y="14" width="6" height="6" rx="1" />
          <rect x="14" y="14" width="6" height="6" rx="1" />
        </svg>
      );
    case 'list-view':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-layout-list"
          width={size}
          height={size}
          viewBox={`0 0 ${boxSize} ${boxSize}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <rect x="4" y="4" width="16" height="6" rx="2" />
          <rect x="4" y="14" width="16" height="6" rx="2" />
        </svg>
      );
    case 'activity':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-activity"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="M3 12h4l3 8 4-16 3 8h4" />
        </svg>
      );
    case 'group':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-affiliate"
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275" />
          <path d="M11.683 12.317l5.759 -5.759" />
          <circle cx="5.5" cy="5.5" r="1.5" />
          <circle cx="18.5" cy="5.5" r="1.5" />
          <circle cx="18.5" cy="18.5" r="1.5" />
          <circle cx="8.5" cy="15.5" r="4.5" />
        </svg>
      );
    case 'software-category':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-brand-pocket"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="M5 4h14a2 2 0 0 1 2 2v6a9 9 0 0 1-18 0V6a2 2 0 0 1 2-2" />
          <path d="m8 11 4 4 4-4" />
        </svg>
      );
    case 'portfolio-themes':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-chart-donut-2"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="M12 3v5m4 4h5" />
          <circle cx="12" cy="12" r="4" />
          <circle cx="12" cy="12" r="9" />
        </svg>
      );
    case 'heatmap':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-layout-2"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <rect x="4" y="4" width="6" height="5" rx="2" />
          <rect x="4" y="13" width="6" height="7" rx="2" />
          <rect x="14" y="4" width="6" height="7" rx="2" />
          <rect x="14" y="15" width="6" height="5" rx="2" />
        </svg>
      );
    case 'profiles':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-template"
          width={size}
          height={size}
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <rect x="4" y="4" width="16" height="4" rx="1" />
          <rect x="4" y="12" width="6" height="8" rx="1" />
          <path d="M14 12h6M14 16h6M14 20h6" />
        </svg>
      );
    case 'report':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-presentation"
          width={size}
          height={size}
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="M3 4h18M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4M12 16v4M9 20h6" />
          <path d="m8 12 3-3 2 2 3-3" />
        </svg>
      );
    case 'tag':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-tag"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="m11 3 9 9a1.5 1.5 0 0 1 0 2l-6 6a1.5 1.5 0 0 1-2 0l-9-9V7a4 4 0 0 1 4-4h4" />
          <circle cx="9" cy="9" r="2" />
        </svg>
      );
    case 'news-categories':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-notebook"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1m3 0v18M13 8h2M13 12h2" />
        </svg>
      );
    case 'm&a':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-layout-grid"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <rect x="4" y="4" width="6" height="6" rx="1" />
          <rect x="14" y="4" width="6" height="6" rx="1" />
          <rect x="4" y="14" width="6" height="6" rx="1" />
          <rect x="14" y="14" width="6" height="6" rx="1" />
        </svg>
      );
    case 'vendors':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-building-arch"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="M3 21h18M4 21V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v15" />
          <path d="M9 21v-8a3 3 0 0 1 6 0v8" />
        </svg>
      );
    case 'vendor-categories':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-picture-in-picture"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="M11 19H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v4" />
          <rect x="14" y="14" width="7" height="5" rx="1" />
        </svg>
      );
    case 'partner-type':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-squares-diagonal"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <rect x="8" y="8" width="12" height="12" rx="2" />
          <path d="M16 8V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2M8.586 19.414 19.413 8.587" />
        </svg>
      );
    case 'partner-level':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-squares-filled"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <rect x="8" y="8" width="12" height="12" rx="2" />
          <path d="m8 14.5 6.492-6.492M13.496 20.004 20 13.5l-6.504 6.504zM8.586 19.414 19.413 8.587M16 8V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2" />
        </svg>
      );
    case 'private-equity':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-social"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <circle cx="12" cy="5" r="2" />
          <circle cx="5" cy="19" r="2" />
          <circle cx="19" cy="19" r="2" />
          <circle cx="12" cy="14" r="3" />
          <path d="M12 7v4M6.7 17.8l2.8-2M17.3 17.8l-2.8-2" />
        </svg>
      );
    case 'platform':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-hierarchy"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <circle cx="12" cy="5" r="2" />
          <circle cx="5" cy="19" r="2" />
          <circle cx="19" cy="19" r="2" />
          <path d="M6.5 17.5 12 13l5.5 4.5M12 7v6" />
        </svg>
      );
    case 'technology':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-3d-cube-sphere"
          width={size}
          height={size}
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none" />
          <path d="m6 17.6-2-1.1V14M4 10V7.5l2-1.1M10 4.1 12 3l2 1.1M18 6.4l2 1.1V10M20 14v2.5l-2 1.12M14 19.9 12 21l-2-1.1M12 12l2-1.1M18 8.6l2-1.1M12 12v2.5M12 18.5V21M12 12l-2-1.12M6 8.6 4 7.5" />
        </svg>
      );
    case 'menu':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-menu-2"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={color}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="4" y1="6" x2="20" y2="6" />
          <line x1="4" y1="12" x2="20" y2="12" />
          <line x1="4" y1="18" x2="20" y2="18" />
        </svg>
      );
    case 'add-new':
        return (
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="icon icon-tabler icon-tabler-new-section" 
                width={size} 
                height={size} 
                viewBox={`0 0 ${boxSize} ${boxSize}`} 
                strokeWidth="1" 
                stroke={color} 
                fill="none" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            >
                <path d="M0 0h24v24H0z" stroke="none"/>
                <path d="M9 12h6M12 9v6M4 6V5a1 1 0 0 1 1-1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1-1 1h-1m-5 0h-2m-5 0H5a1 1 0 0 1-1-1v-1m0-5v-2m0-5"/>
            </svg>
        )
    case 'portfolio':
        return (
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="icon icon-tabler icon-tabler-manual-gearbox" 
                width={size} 
                height={size} 
                viewBox={`0 0 ${size} ${size}`} 
                strokeWidth="1.5" 
                stroke={color} 
                fill="none" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            >
                <path d="M0 0h24v24H0z" stroke="none"/>
                <circle cx="5" cy="6" r="2"/>
                <circle cx="12" cy="6" r="2"/>
                <circle cx="19" cy="6" r="2"/>
                <circle cx="5" cy="18" r="2"/>
                <circle cx="12" cy="18" r="2"/>
                <path d="M5 8v8M12 8v8M19 8v2a2 2 0 01-2 2H5"/>
            </svg>
        )
    case 'chartSettings':
      return (
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="icon icon-tabler icon-tabler-presentation-analytics" 
          width={size} 
          height={size} 
          viewBox={`0 0 ${size} ${size}`} 
          strokeWidth="1.5" 
          stroke={color} 
          fill="none" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="M0 0h24v24H0z" stroke="none"/>
          <path d="M9 12V8M15 12v-2M12 12v-1M3 4h18M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4M12 16v4M9 20h6"/>
        </svg>
      )
      case 'country':
        return (
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="icon icon-tabler icon-tabler-tools" 
            width={size} 
            height={size} 
            viewBox={`0 0 ${size} ${size}`} 
            strokeWidth="1.5" 
            stroke={color} 
            fill="none" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M0 0h24v24H0z" stroke="none"/>
            <path d="M3 21h4L20 8a1.5 1.5 0 0 0-4-4L3 17v4M14.5 5.5l4 4"/>
            <path d="M12 8 7 3 3 7l5 5M7 8 5.5 9.5M16 12l5 5-4 4-5-5M16 17l-1.5 1.5"/>
          </svg>
        )
        case 'm&a_deal_tracker':
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon
              icon-tabler icon-tabler-tornado" 
              width={size} 
              height={size} 
              viewBox={`0 0 ${size} ${size}`} 
              strokeWidth="1.5" 
              stroke={color} 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M0 0h24v24H0z" stroke="none"/>
              <path d="M21 4H3M13 16H7M11 20h4M6 8h14M4 12h12"/>
            </svg>
          )
        case 'strategic-investor':
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon icon-tabler icon-tabler-calendar-plus" 
              width={size} 
              height={size} 
              viewBox={`0 0 ${size} ${size}`} 
              strokeWidth="1.5" 
              stroke={color} 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              >
                <path d="M0 0h24v24H0z" stroke="none"/>
                <rect x="4" y="5" width="16" height="16" rx="2"/>
                <path d="M16 3v4M8 3v4M4 11h16M10 16h4M12 14v4"/>
            </svg>
          )
        case 'federation':
          return (
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="icon icon-tabler icon-tabler-building-warehouse" 
            width={size}
            height={size} 
            viewBox="0 0 24 24" 
            strokeWidth="1.5" 
            stroke={color} 
            fill="none" 
            strokeLinecap="round" 
            strokeLinejoin="round">
              <path d="M0 0h24v24H0z" stroke="none"/>
              <path d="M3 21V8l9-4 9 4v13"/>
              <path d="M13 13h4v8H7v-6h6"/>
              <path d="M13 21v-9a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v3"/>
          </svg>
          )
        case 'industry':
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon icon-tabler icon-tabler-list-details" 
              width={size} 
              height={size}
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke={color}
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round">
                <path d="M0 0h24v24H0z" stroke="none"/>
                <path d="M13 5h8M13 9h5M13 15h8M13 19h5"/>
                <rect x="3" y="4" width="6" height="6" rx="1"/>
                <rect x="3" y="14" width="6" height="6" rx="1"/>
            </svg>
          )
        case 'job-role':
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon icon-tabler icon-tabler-man" 
              width="32" 
              height="32" 
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke="#9e9e9e" 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round">
                <path d="M0 0h24v24H0z" stroke="none"/>
                <circle cx="12" cy="5" r="2"/>
                <path d="M10 22v-5l-1-1v-4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4l-1 1v5"/>
            </svg>
          )
        case 'clickworker-profile':
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon icon-tabler icon-tabler-device-laptop" 
              width={size}
              height={size}
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke={color} 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="3" y1="19" x2="21" y2="19" />
                <rect x="5" y="6" width="14" height="10" rx="1" />
          </svg>
          )
        case 'invitation':
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon icon-tabler icon-tabler-mail-forward" 
              width={size} 
              height={size} 
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke={color} 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round">
                <path d="M0 0h24v24H0z" stroke="none"/>
                <path d="M12 18H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v7.5"/>
                <path d="m3 6 9 6 9-6M15 18h6M18 15l3 3-3 3"/>
            </svg>
          )
        case 'share':
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon icon-tabler icon-tabler-send" 
              width={size} 
              height={size} 
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke={color} 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round">
                <path d="M0 0h24v24H0z" stroke="none"/>
                <path d="M10 14 21 3M21 3l-6.5 18a.55.55 0 0 1-1 0L10 14l-7-3.5a.55.55 0 0 1 0-1L21 3"/>
            </svg>
          )
        case 'user-plus':
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon icon-tabler icon-tabler-user-plus" 
              width={size}  
              height={size}  
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke={color} 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round">
                <path d="M0 0h24v24H0z" stroke="none"/>
                <circle cx="9" cy="7" r="4"/>
                <path d="M3 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2M16 11h6m-3-3v6"/>
            </svg>
          )
        case 'mail': {
          return (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="icon icon-tabler icon-tabler-mail" 
              width={size} 
              height={size} 
              viewBox="0 0 24 24" 
              strokeWidth="1" 
              stroke={color} 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round">
                <path d="M0 0h24v24H0z" stroke="none"/>
                <rect x="3" y="5" width="18" height="14" rx="2"/>
                <path d="m3 7 9 6 9-6"/>
              </svg>
          )
        }
        default:
          return null;
  }
};

const Icon = ({ name, color, size, fill }) => {
  return renderIcon(name, color, size, fill);
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

Icon.defaultProps = {
  color: '#CCD0D6',
  size: ICON_SIZE.LARGE,
  fill: 'none'
};

export default Icon;
