import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { CurrentUserContext } from 'context/CurrentUserContext'
import { useLocation } from 'react-router-dom'
import useMenuItems from './useMenuItems'

import SidebarItem from './SidebarItem'
import ROUTES from 'constants/routes'

const Sidebar = ({ open, setOpen, hocRef }) => {
    const { pathname } = useLocation()
    const { currentUser } = useContext(CurrentUserContext)
    const menuItems = useMenuItems(currentUser?.userRoles)

    const getIsActive = to => {
        if (to !== ROUTES.HOME) return pathname.includes(to)
        return pathname === ROUTES.HOME
    }

    return (
        <div ref={hocRef} className={`m-sidenav ${open ? '' : '-closed'}`}>
            <div className='m-sidenav__toggler' onClick={() => setOpen(!open)} />
            <div className='m-sidenav__scroll'>
                {menuItems.map(({ key, to, action, label, icon, separator }) => (
                    separator ? <div key={key} className="m-sidenav__separator"/> :
                    <SidebarItem
                        key={key}
                        label={label}
                        icon={icon}
                        to={to}
                        active={getIsActive(to)}
                        onClick={action}
                    />
                )
                )}
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
}

export default Sidebar
