import React, { useContext } from 'react'

import { TranslationContext } from 'context/TranslationContext';

import ImprintDE from './Imprint-DE';
import ImprintEN from './Imprint-EN';
import FAQ from 'screens/noAuth/FAQ';
import Footer from 'components/Layout/Footer';

const Imprint = () => {

  const { translations: { locale } } = useContext(TranslationContext)

  return (
    <div className='m-imprint'>
      {locale === 'de' ? <ImprintDE /> : <ImprintEN />}
      <FAQ/>
      <Footer />
    </div>
  )
}

export default Imprint