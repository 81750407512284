import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { getUserToken } from 'services/localStorage.service'

import Logo from 'components/Logo'
import Footer from '../Layout/Footer'
import FAQ from '../../screens/noAuth/FAQ'

import ROUTES from 'constants/routes'

const NoAuthRoute = ({ path, component }) => {

    const token = getUserToken()

    if (token)  return <Redirect to={ROUTES.HOME} />
   
    return (
    <div className='m-noAuth'>
        <section className='m-noAuth__logoForm'>
            <Logo />
            <div className='m-noAuth__form'>
                <Route exact path={path} component={component} />
            </div>
        </section>     
        <div className='m-noAuth__hero'>
            <div className='m-noAuth__hero--overlay'></div>
        </div>
        <FAQ/>
        <Footer />
    </div>
)

}

export default NoAuthRoute