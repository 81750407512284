import React, { useState, useEffect, useContext } from 'react'
import { editEntityService, getEntityByIdService } from 'services/entity.service'
import { getUserLanguage, setUserLanguage } from 'services/localStorage.service'
import { CurrentUserContext } from './CurrentUserContext'
import ENTITY from 'constants/entities'

const DEFAULT_LANGUAGE = 'en'

export const TranslationContext = React.createContext()

const TranslationContextProvider = (props) => {
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
    
    const currentLanguage = getUserLanguage() || DEFAULT_LANGUAGE

    const [translations, setTranslations] = useState({
        locale: currentLanguage,
        messages: require(`../translations/${currentLanguage}.json`)
    })

    useEffect(() => {
        if (currentUser) {
            setTranslations({ ...translations, locale: currentUser?.language?.code })
        } else {
            setTranslations({ ...translations, locale: currentLanguage })
        }
    }, [currentUser])

    useEffect(async () => {
        const messages = require(`../translations/${translations.locale}.json`);
        setUserLanguage(translations.locale)
        setTranslations({ ...translations, messages })
    }, [translations.locale])

    const handleSetTranslations = async (language) => {
        try {
            if (currentUser) {
                await editEntityService(ENTITY.USER, currentUser.id, { language: { id: language.id, type: language.entityType } })
                const data = await getEntityByIdService(ENTITY.USER, currentUser.id, { include: 'userRoles,language,avatar,jobRole,package,privateEquities,company,country,billingCountry' })
                setCurrentUser(data)
            } else {
                setTranslations({ ...translations, locale: language.code })
            }
        } catch (error) {
            throw (error)
        }
    }

    const { locale, messages } = translations
    if (!locale || !messages) return null

    return (
        <TranslationContext.Provider value={{
            translations,
            setTranslations: handleSetTranslations
        }}>
            {props.children}
        </TranslationContext.Provider>
    )
}

export default TranslationContextProvider
