import React, { useContext, Fragment } from 'react'
import { Redirect, BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { I18n } from 'react-polyglot'
import loadable from 'utils/loadable'

import { TranslationContext } from 'context/TranslationContext'

import ROUTES from 'constants/routes'

import Alert from 'components/Alert'

import AuthRoute from 'components/routes/AuthRoute'
import NoAuthRoute from 'components/routes/NoAuthRoute'
import PropsRoute from 'components/routes/PropsRoute'

import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import SingleNews from "./screens/user/Updates/SingleNews";
import Imprint from 'screens/Imprint'
import GeneralTerms from 'screens/GeneralTermsConditions'
import ImprintDE from 'screens/Imprint/Imprint-DE'
import GeneralTermsDE from 'screens/GeneralTermsConditions/GeneralTermsConditionsDE'
import PricingTableDe from 'screens/PricingTable/PricingTableDE'
import PricingTable from 'screens/PricingTable'
import DataProtectionDE from 'screens/DataProtection/DataProtectionDE'
import DataProtection from 'screens/DataProtection'
import { ROLES } from 'constants/enums'

const Login = loadable(() => import('screens/noAuth/Login'))
const Register = loadable(() => import('screens/noAuth/Register'))
const Welcome = loadable(() => import('screens/noAuth/Welcome'))
const ForgotPassword = loadable(() => import('screens/noAuth/ForgotPassword'))
const ResetPassword = loadable(() => import('screens/noAuth/ResetPassword'))
const ActivateUser = loadable(() => import('screens/noAuth/ActivateUser'))
const Premium = loadable(() => import('screens/noAuth/Premium/index'))

const Home = loadable(() => import('screens/static/Home'))
const CompanyOwners = loadable(() => import('screens/static/CompanyOwners'))

const Dashboard = loadable(() => import('screens/Dashboard'))
const DataOverview = loadable(() => import('screens/DataOverview'))
const UserProfile = loadable(() => import('screens/UserProfile'))

const UserManagement = loadable(() => import('screens/admin/UserManagement'))
const CreateUser = loadable(() => import('screens/admin/UserManagement/CreateUser'))
const EditUser = loadable(() => import('screens/admin/UserManagement/EditUser'))

const FilterManagement = loadable(() => import('screens/admin/FilterManagement'))
const FilterList = loadable(() => import('screens/admin/FilterManagement/Filter/FilterList'))
const CreateFilter = loadable(() => import('screens/admin/FilterManagement/Filter/CreateFilter'))
const EditFilter = loadable(() => import('screens/admin/FilterManagement/Filter/EditFilter'))
const CategoryList = loadable(() => import('screens/admin/FilterManagement/Category/CategoryList'))
const CreateCategory = loadable(() => import('screens/admin/FilterManagement/Category/CreateCategory'))
const EditCategory = loadable(() => import('screens/admin/FilterManagement/Category/EditCategory'))
const FilterItemList = loadable(() => import('screens/admin/FilterManagement/FilterItem/FilterItemList'))
const CreateFilterItem = loadable(() => import('screens/admin/FilterManagement/FilterItem/CreateFilterItem'))
const EditFilterItem = loadable(() => import('screens/admin/FilterManagement/FilterItem/EditFilterItem'))

const TranslationManagement = loadable(() => import('./screens/admin/TranslationManagement'))
const EditTranslation = loadable(() => import('./screens/admin/TranslationManagement/EditTranslation'))

const Companies = loadable(() => import('screens/user/Companies'))
const CountriesManagement = loadable(() => import('screens/admin/CountriesManagement'))
const CompanyProfile = loadable(() => import('screens/user/Companies/CompanyProfile'))
const SimilarCompanies = loadable(() => import('screens/user/Companies/CompanyProfile/SimilarCompanies'))
const OverviewCards = loadable(() => import('screens/user/Companies/CompanyProfile/OverviewCards'))
const Heatmap = loadable(() => import('screens/user/Companies/CompanyProfile/Heatmap'))
const NewsCards = loadable(() => import('screens/user/Companies/CompanyProfile/NewsCards'))
const DealProcess = loadable(() => import('screens/user/DealProcess'))
const Statuses = loadable(() => import('screens/user/DealProcess/Statuses'))
const SuccessBarometer = loadable(() => import('screens/user/DealProcess/SuccessBarometer'))
const SavedProfiles = loadable(() => import('screens/user/SavedProfiles'))
const ProfileMatches = loadable(() => import('screens/user/SavedProfiles/ProfileMatches'))
const SavedSearches = loadable(() => import('screens/user/SavedProfiles/SavedSearches'))
const Searches = loadable(() => import('screens/user/SavedProfiles/SavedSearches/Searches'))
const SavedCompanies = loadable(() => import('screens/user/SavedProfiles/SavedCompanies'))
const Notes = loadable(() => import('screens/user/Companies/CompanyProfile/Notes'))
const SavedSearch = loadable(() => import('screens/user/SavedProfiles/SavedSearches/SavedSearch'))
const ComparisonHeatmap = loadable(() => import('screens/user/Heatmap'))
const ComparisonTools = loadable(() => import('screens/user/Heatmap/ComparisonTools'))
const CompetitorAnalysis = loadable(() => import('screens/user/Heatmap/CompetitorAnalysis'))
const PortfolioManagement = loadable(() => import('screens/user/PortfolioManagement'))
const OwnPortfolio = loadable(() => import('screens/user/PortfolioManagement/OwnPortfolio'))
const PlatformOverview = loadable(() => import('screens/user/PortfolioManagement/PlatformOverview'))
const Platforms = loadable(() => import('screens/user/PortfolioManagement/PlatformOverview/Platforms'))
const PlatformCompanies = loadable(() => import('screens/user/PortfolioManagement/PlatformOverview/PlatformCompanies'))

const Updates = loadable(() => import('screens/user/Updates'))
// const TechnologyTrends = loadable(() => import('screens/user/Updates/TechnologyTrends'))
const Reports = loadable(() => import('screens/user/Updates/Reports'))
const DealTracker = loadable(() => import('screens/user/Updates/DealTracker'))
const News = loadable(() => import('screens/user/Updates/News'))

const CompanyManagement = loadable(() => import('screens/admin/CompanyManagement'))
const CreateCompany = loadable(() => import('screens/admin/CompanyManagement/CreateCompany'))
const EditCompany = loadable(() => import('screens/admin/CompanyManagement/EditCompany'))

const SavedSearchesManagement = loadable(() => import('screens/admin/SavedSearchesManagement'))

const TeamMembersManagement = loadable(() => import('screens/admin/CompanyManagement/TeamMembersManagement'))
const CreateTeamMember = loadable(() => import('screens/admin/CompanyManagement/TeamMembersManagement/CreateTeamMember'))
const EditTeamMember = loadable(() => import('screens/admin/CompanyManagement/TeamMembersManagement/EditTeamMember'))

const CompanyEmployeesManagement = loadable(() => import('screens/admin/CompanyManagement/CompanyEmployeesManagement'))
const CreateCompanyEmployee = loadable(() => import('screens/admin/CompanyManagement/CompanyEmployeesManagement/CreateCompanyEmployee'))
const EditCompanyEmployee = loadable(() => import('screens/admin/CompanyManagement/CompanyEmployeesManagement/EditCompanyEmployee'))

const RevenueManagement = loadable(() => import('screens/admin/CompanyManagement/RevenueManagement'))
const CreateRevenue = loadable(() => import('screens/admin/CompanyManagement/RevenueManagement/CreateRevenue'))
const EditRevenue = loadable(() => import('screens/admin/CompanyManagement/RevenueManagement/EditRevenue'))

const TechnologyStackManagement = loadable(() => import('screens/admin/TechnologyStackManagement'))
const CreateTechnologyStack = loadable(() => import('screens/admin/TechnologyStackManagement/CreateTechnologyStack'))
const EditTechnologyStack = loadable(() => import('screens/admin/TechnologyStackManagement/EditTechnologyStack'))

const TagManagement = loadable(() => import('screens/admin/TagManagement'))
const CreateTag = loadable(() => import('screens/admin/TagManagement/CreateTag'))
const EditTag = loadable(() => import('screens/admin/TagManagement/EditTag'))

const NewsCategoryManagement = loadable(() => import('screens/admin/NewsCategoryManagement'))
const CreateNewsCategory = loadable(() => import('screens/admin/NewsCategoryManagement/CreateNewsCategory'))
const EditNewsCategory = loadable(() => import('screens/admin/NewsCategoryManagement/EditNewsCategory'))

const MA_DealTracker = loadable(() => import('screens/admin/MADealTracker'))
const CreateMADealTracker = loadable(() => import('screens/admin/MADealTracker/CreateMADealTracker'))
const EditMADealTracker = loadable(() => import('screens/admin/MADealTracker/EditMADealTracker'))

const NewsManagement = loadable(() => import('screens/admin/NewsManagement'))
const CreateNews = loadable(() => import('screens/admin/NewsManagement/CreateNews'))
const EditNews = loadable(() => import('screens/admin/NewsManagement/EditNews'))

const AcquisitionNewsManagement = loadable(() => import('screens/admin/AcquisitionNewsManagement'))
const CreateAcquisitionNews = loadable(() => import('screens/admin/AcquisitionNewsManagement/CreateAcquisitionNews'))
const EditAcquisitionNews = loadable(() => import('screens/admin/AcquisitionNewsManagement/EditAcquisitionNews'))

const PrivateEquityManagement = loadable(() => import('screens/admin/PrivateEquityManagement'))
const CreatePrivateEquity = loadable(() => import('screens/admin/PrivateEquityManagement/CreatePrivateEquity'))
const EditPrivateEquity = loadable(() => import('screens/admin/PrivateEquityManagement/EditPrivateEquity'))

const PlatformManagement = loadable(() => import('screens/admin/PlatformManagement'))
const CreatePlatform = loadable(() => import('screens/admin/PlatformManagement/CreatePlatform'))
const EditPlatform = loadable(() => import('screens/admin/PlatformManagement/EditPlatform'))

const VendorManagement = loadable(() => import('screens/admin/VendorManagement'))
const CreateVendor = loadable(() => import('screens/admin/VendorManagement/CreateVendor'))
const EditVendor = loadable(() => import('screens/admin/VendorManagement/EditVendor'))

const VendorCategoryManagement = loadable(() => import('screens/admin/VendorCategoryManagement'))
const CreateVendorCategory = loadable(() => import('screens/admin/VendorCategoryManagement/CreateVendorCategory'))
const EditVendorCategory = loadable(() => import('screens/admin/VendorCategoryManagement/EditVendorCategory'))

const PartnerTypeManagement = loadable(() => import('screens/admin/PartnerTypeManagement'))
const CreatePartnerType = loadable(() => import('screens/admin/PartnerTypeManagement/CreatePartnerType'))
const EditPartnerType = loadable(() => import('screens/admin/PartnerTypeManagement/EditPartnerType'))

const PartnerLevelManagement = loadable(() => import('screens/admin/PartnerLevelManagement'))
const CreatePartnerLevel = loadable(() => import('screens/admin/PartnerLevelManagement/CreatePartnerLevel'))
const EditPartnerLevel = loadable(() => import('screens/admin/PartnerLevelManagement/EditPartnerLevel'))

const ActivityManagement = loadable(() => import('screens/admin/ActivityManagement'))
const CreateActivity = loadable(() => import('screens/admin/ActivityManagement/CreateActivity'))
const EditActivity = loadable(() => import('screens/admin/ActivityManagement/EditActivity'))

const IndustryManagement = loadable(() => import('screens/admin/IndustryManagement'))
const CreateIndustry = loadable(() => import('screens/admin/IndustryManagement/CreateIndustry'))
const EditIndustry = loadable(() => import('screens/admin/IndustryManagement/EditIndustry'))

const SoftwareCategoryManagement = loadable(() => import('screens/admin/SoftwareCategoryManagement'))
const CreateSoftwareCategory = loadable(() => import('screens/admin/SoftwareCategoryManagement/CreateSoftwareCategory'))
const EditSoftwareCategory = loadable(() => import('screens/admin/SoftwareCategoryManagement/EditSoftwareCategory'))

const PortfolioThemesManagement = loadable(() => import('screens/admin/PortfolioThemesManagement'))
const CreatePortfolioThemes = loadable(() => import('screens/admin/PortfolioThemesManagement/CreatePortfolioThemes'))
const EditPortfolioThemes = loadable(() => import('screens/admin/PortfolioThemesManagement/EditPortfolioThemes'))

const TechnologyManagement = loadable(() => import('screens/admin/TechnologyManagement'))
const CreateTechnology = loadable(() => import('screens/admin/TechnologyManagement/CreateTechnology'))
const EditTechnology = loadable(() => import('screens/admin/TechnologyManagement/EditTechnology'))

const TechMonitorsManagement = loadable(() => import('screens/admin/TechMonitorsManagement'))

const StrategicInvestorManagement = loadable(() => import('screens/admin/StrategicInvestors'))
const CreateStrategicInvestor = loadable(() => import('screens/admin/StrategicInvestors/CreateStrategInvestor'))
const EditStrategicInvestor = loadable(() => import('screens/admin/StrategicInvestors/EditStrategicInvestor'))

const FederationManagement = loadable(() => import('screens/admin/FederationManagement'))
const CreateFederation = loadable(() => import('screens/admin/FederationManagement/CreateFederation'))
const EditFederation = loadable(() => import('screens/admin/FederationManagement/EditFederation'))

const GroupManagement = loadable(() => import('screens/admin/GroupManagement'))
const CreateGroup = loadable(() => import('screens/admin/GroupManagement/CreateGroup'))
const EditGroup = loadable(() => import('screens/admin/GroupManagement/EditGroup'))

const BuyersManagement = loadable(() => import('screens/admin/CompanyManagement/BuyersManagement'))
const CreateBuyer = loadable(() => import('screens/admin/CompanyManagement/BuyersManagement/CreateBuyer'))
const EditBuyer = loadable(() => import('screens/admin/CompanyManagement/BuyersManagement/EditBuyer'))


const PaypalSuccess = loadable(() => import('screens/noAuth/Premium/PaypalSuccess'))
const PaypalError = loadable(() => import('screens/noAuth/Premium/PaypalError'))

const JobRoleManagement = loadable(() => import('screens/admin/JobRoleManagement'))
const CreateJobRole = loadable(() => import('screens/admin/JobRoleManagement/CreateJobRole'))
const EditJobRole = loadable(() => import('screens/admin/JobRoleManagement/EditJobRole'))

const ClickworkerProfile = loadable(() => import('screens/clickworker/ClickworkerProfile'))
const ClickworkerSearches = loadable(() => import('screens/clickworker/ClickworkerSearches'))
const ClickworkerCompanies = loadable(() => import('screens/clickworker/ClickworkerCompanies'))

const FormerNamesManagement = loadable(() => import('screens/admin/CompanyManagement/FormerNamesManagement'))
const CreateFormerName = loadable(() => import('screens/admin/CompanyManagement/FormerNamesManagement/CreateFormerName'))
const EditFormerName = loadable(() => import('screens/admin/CompanyManagement/FormerNamesManagement/EditFormerName'))

const Invitations = loadable(() => import('screens/user/Invitations'))
const InvitationsReceived = loadable(() => import('screens/user/Invitations/InvitationsReceived'))
const InvitationsSent = loadable(() => import('screens/user/Invitations/InvitationsSent'))

const CompanyVendorsManagement = loadable(() => import('screens/admin/CompanyManagement/CompanyVendorsManagement'))
const CreateCompanyVendor = loadable(() => import('screens/admin/CompanyManagement/CompanyVendorsManagement/CreateCompanyVendor'))
const EditCompanyVendor = loadable(() => import('screens/admin/CompanyManagement/CompanyVendorsManagement/EditCompanyVendor'))


const App = () => {
  const { translations } = useContext(TranslationContext)

  const {
    ADMIN,
    INVESTOR,
    ENTREPRENEUR,
    CLICK_WORKER
  } = ROLES

  return (
    <Router>
      <I18n locale={translations.locale} messages={translations.messages}>
        <Fragment>
          <Alert />
          <Switch>
            <NoAuthRoute
              path={ROUTES.LOGIN}
              component={Login}
            />
            {/* <Route
              exact
              path={ROUTES.LINKEDIN}
              component={LinkedInPopUp}
            /> */}
            {/* <Route
              path={ROUTES.REGISTER}
              component={Register}
            /> */}

            <NoAuthRoute
              path={ROUTES.FORGOT_PASSWORD}
              component={ForgotPassword}
              exact
            />
            <NoAuthRoute
              path={`${ROUTES.RESET_PASSWORD}/:token`}
              component={ResetPassword}
            />
            <NoAuthRoute
              path={`${ROUTES.ACTIVATE_USER}/:token`}
              component={ActivateUser}
            />
            {/* <Route
              path={`${ROUTES.ACTIVATE_USER}/:token`}
              component={Welcome}
            /> */}
            <Route
              path={ROUTES.PREMIUM}
              component={Premium}
              exact={true}
            />
            <Route
              path={ROUTES.IMPRINT_PAGE}
              component={Imprint}
              exact
            />
            <Route
              path={ROUTES.DATA_PROTECTION}
              component={DataProtection}
              exact
            />
            <Route
              path={ROUTES.GENERAL_TERMS}
              component={GeneralTerms}
              exact
            />
            <Route
              path={ROUTES.PRICING_PAGE}
              component={PricingTable}
              exact
            />
            <AuthRoute
              path={ROUTES.HOME}
              component={Dashboard}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR, CLICK_WORKER]}
              exact
            />
            <AuthRoute
              path={ROUTES.TECH_MONITORS}
              component={DataOverview}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              exact
            />
            <Route
              path={ROUTES.COMPANY_OWNERS}
              component={CompanyOwners}
            />
            <AuthRoute
              path={ROUTES.SAVED_PROFILES}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              render={({ match: { url } }) => (
                <SavedProfiles>
                  <Route
                    path={`${url}`}
                    render={({ match: { url } }) => (
                      <SavedSearches>
                        <Route
                          path={`${url}${ROUTES.SAVED_SEARCHES}`}
                          component={Searches}
                          exact
                        />
                        <Route
                          path={`${url}${ROUTES.SAVED_SEARCHES}${ROUTES.SAVED_SEARCHES}/:id`}
                          component={SavedSearch}
                        />
                      </SavedSearches>
                    )}
                  />
                  <Route
                    //path={`${url}${ROUTES.SEARCH_PROFILE_MATCHES}`}
                    path={`${url}`}
                    component={ProfileMatches}
                    exact
                  />
                  <Route
                    path={`${url}${ROUTES.SAVED_COMPANIES}`}
                    component={SavedCompanies}
                  />
                  {/* <Route
                    path={`${url}${ROUTES.YOUR_NOTES}`}
                    component={Notes}
                  /> */}
                </SavedProfiles>
              )}
            />
            <AuthRoute
              path={ROUTES.USER_MANAGEMENT}
              component={UserManagement}
              allowedRoles={[ADMIN]}
              exact
            />
            <AuthRoute
              path={`${ROUTES.USER_MANAGEMENT}${ROUTES.CREATE}`}
              component={CreateUser}
              allowedRoles={[ADMIN]}
            />
            <AuthRoute
              path={`${ROUTES.USER_MANAGEMENT}${ROUTES.EDIT}/:id`}
              component={EditUser}
              allowedRoles={[ADMIN]}
            />
            <AuthRoute
              path={ROUTES.FILTERS}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <FilterManagement>
                  <Route
                    path={url}
                    component={FilterList}
                    exact
                  />
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateFilter}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditFilter}
                  />
                  <Route
                    path={`${url}${ROUTES.CATEGORIES}`}
                    component={CategoryList}
                    exact
                  />
                  <Route
                    path={`${url}${ROUTES.CATEGORIES}${ROUTES.CREATE}`}
                    component={CreateCategory}
                  />
                  <Route
                    path={`${url}${ROUTES.CATEGORIES}${ROUTES.EDIT}/:id`}
                    component={EditCategory}
                  />
                  <Route
                    path={`${url}${ROUTES.FILTER_ITEMS}`}
                    component={FilterItemList}
                    exact
                  />
                  <Route
                    path={`${url}${ROUTES.FILTER_ITEMS}${ROUTES.CREATE}`}
                    component={CreateFilterItem}
                  />
                  <Route
                    path={`${url}${ROUTES.FILTER_ITEMS}${ROUTES.EDIT}/:id`}
                    component={EditFilterItem}
                  />
                </FilterManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.COMPANY_MANAGEMENT}`}
              allowedRoles={[ADMIN, CLICK_WORKER]}
              render={({ match: { url } }) => (
                <CompanyManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateCompany}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id/:currentTab`}
                    component={EditCompany}
                  />
                  <Route
                    path={`${url}${ROUTES.TEAM_MANAGEMENT}${ROUTES.CREATE}/:companyId`}
                    component={CreateTeamMember}
                  />
                  <Route
                    path={`${url}${ROUTES.TEAM_MANAGEMENT}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditTeamMember}
                  />
                  <Route
                    path={`${url}${ROUTES.EMPLOYEES_MANAGEMENT}${ROUTES.CREATE}/:companyId`}
                    component={CreateCompanyEmployee}
                  />
                  <Route
                    path={`${url}${ROUTES.EMPLOYEES_MANAGEMENT}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditCompanyEmployee}
                  />
                  <Route
                    path={`${url}${ROUTES.FINANCIALS_MANAGEMENT}${ROUTES.CREATE}/:companyId`}
                    component={CreateRevenue}
                  />
                  <Route
                    path={`${url}${ROUTES.FINANCIALS_MANAGEMENT}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditRevenue}
                  />
                  <Route
                    path={`${url}${ROUTES.BUYERS_MANAGEMENT}${ROUTES.CREATE}/:companyId`}
                    component={CreateBuyer}
                  />
                  <Route
                    path={`${url}${ROUTES.BUYERS_MANAGEMENT}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditBuyer}
                  />
                  <Route
                    path={`${url}${ROUTES.FORMER_NAMES_MANAGEMENT}${ROUTES.CREATE}/:companyId`}
                    component={CreateFormerName}
                  />
                  <Route
                    path={`${url}${ROUTES.FORMER_NAMES_MANAGEMENT}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditFormerName}
                  />
                  <Route
                    path={`${url}${ROUTES.COMPANY_VENDORS_MANAGEMENT}${ROUTES.CREATE}/:companyId`}
                    component={CreateCompanyVendor}
                  />
                  <Route
                    path={`${url}${ROUTES.COMPANY_VENDORS_MANAGEMENT}${ROUTES.EDIT}/:companyId/:vendorId`}
                    component={EditCompanyVendor}
                  />
                </CompanyManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.SAVED_SEARCHES_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <SavedSearchesManagement />
              )}
            />
            <AuthRoute
              path={`${ROUTES.TEAM_MANAGEMENT}/:id`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <TeamMembersManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}/:companyId`}
                    component={CreateTeamMember}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditTeamMember}
                  />
                </TeamMembersManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.EMPLOYEES_MANAGEMENT}/:id`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <CompanyEmployeesManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}/:companyId`}
                    component={CreateCompanyEmployee}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditCompanyEmployee}
                  />
                </CompanyEmployeesManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.BUYERS_MANAGEMENT}/:id`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <BuyersManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}/:companyId`}
                    component={CreateBuyer}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditBuyer}
                  />
                </BuyersManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.FORMER_NAMES_MANAGEMENT}/:id`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <FormerNamesManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}/:companyId`}
                    component={CreateFormerName}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditFormerName}
                  />
                </FormerNamesManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.COMPANY_VENDORS_MANAGEMENT}/:id`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <CompanyVendorsManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}/:companyId`}
                    component={CreateCompanyVendor}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:companyId/:vendorId`}
                    component={EditCompanyVendor}
                  />
                </CompanyVendorsManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.FEDERATION_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <FederationManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateFederation}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditFederation}
                  />
                </FederationManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.FINANCIALS_MANAGEMENT}/:id`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <RevenueManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}/:companyId`}
                    component={CreateRevenue}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:companyId/:id`}
                    component={EditRevenue}
                  />
                </RevenueManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.NEWS_CATEGORY_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <NewsCategoryManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateNewsCategory}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditNewsCategory}
                  />
                </NewsCategoryManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.NEWS_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <NewsManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateNews}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditNews}
                  />
                </NewsManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.VENDOR_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <VendorManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateVendor}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditVendor}
                  />
                </VendorManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.COUNTRIES_MENAGEMENT}`}
              allowedRoles={[ADMIN]}
              component={CountriesManagement}
            />
            <AuthRoute
              path={`${ROUTES.DEAL_TRACKER_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <MA_DealTracker>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateMADealTracker}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditMADealTracker}
                  />
                </MA_DealTracker>
              )}
            />
            <AuthRoute
              path={`${ROUTES.STRATEGIC_INVESTOR_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <StrategicInvestorManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateStrategicInvestor}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditStrategicInvestor}
                  />
                </StrategicInvestorManagement>
              )}
            />
            {/* <AuthRoute
                path={`${ROUTES.VENDOR_CATEGORY_MANAGEMENT}`}
                render={({ match: { url } }) => (
                    <VendorCategoryManagement>
                        <Route
                            path={`${url}${ROUTES.CREATE}`}
                            component={CreateVendorCategory}
                        />
                        <Route
                            path={`${url}${ROUTES.EDIT}/:id`}
                            component={EditVendorCategory}
                        />
                    </VendorCategoryManagement>
                )}
            /> */}
            <AuthRoute
              path={`${ROUTES.PARTNER_TYPE_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <PartnerTypeManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreatePartnerType}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditPartnerType}
                  />
                </PartnerTypeManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.PARTNER_LEVEL_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <PartnerLevelManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreatePartnerLevel}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditPartnerLevel}
                  />
                </PartnerLevelManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.ACQUISITION_NEWS_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <AcquisitionNewsManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateAcquisitionNews}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditAcquisitionNews}
                  />
                </AcquisitionNewsManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.PRIVATE_EQUITY_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <PrivateEquityManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreatePrivateEquity}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditPrivateEquity}
                  />
                </PrivateEquityManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.PLATFORM_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <PlatformManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreatePlatform}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditPlatform}
                  />
                </PlatformManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.ACTIVITY_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <ActivityManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateActivity}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditActivity}
                  />
                </ActivityManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.INDUSTRY_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <IndustryManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateIndustry}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditIndustry}
                  />
                </IndustryManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.SOFTWARE_CATEGORY_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <SoftwareCategoryManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateSoftwareCategory}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditSoftwareCategory}
                  />
                </SoftwareCategoryManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.PORTFOLIO_THEMES_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <PortfolioThemesManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreatePortfolioThemes}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditPortfolioThemes}
                  />
                </PortfolioThemesManagement>
              )}
            />
            <AuthRoute
              path={`${ROUTES.TECH_MONITORS_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              component={TechMonitorsManagement}
            />
            <AuthRoute
              path={`${ROUTES.GROUP_MANAGEMENT}`}
              allowedRoles={[ADMIN]}
              component={GroupManagement}
              exact
            />
            <AuthRoute
              path={`${ROUTES.GROUP_MANAGEMENT}${ROUTES.CREATE}`}
              allowedRoles={[ADMIN]}
              component={CreateGroup}
            />
            <AuthRoute
              path={`${ROUTES.GROUP_MANAGEMENT}${ROUTES.EDIT}/:id`}
              allowedRoles={[ADMIN]}
              component={EditGroup}
            />
            {/* <AuthRoute
                path={`${ROUTES.TECHNOLOGY_MANAGEMENT}`}
                render={({ match: { url } }) => (
                    <TechnologyManagement>
                        <Route
                            path={`${url}${ROUTES.CREATE}`}
                            component={CreateTechnology}
                        />
                        <Route
                            path={`${url}${ROUTES.EDIT}/:id`}
                            component={EditTechnology}
                        />
                    </TechnologyManagement>
                )}
            /> */}
            <AuthRoute
              path={ROUTES.TECHNOLOGY_STACK_MANAGEMENT}
              allowedRoles={[ADMIN]}
              component={TechnologyStackManagement}
              exact
            />
            <AuthRoute
              path={`${ROUTES.TECHNOLOGY_STACK_MANAGEMENT}${ROUTES.CREATE}`}
              allowedRoles={[ADMIN]}
              component={CreateTechnologyStack}
            />
            <AuthRoute
              path={`${ROUTES.TECHNOLOGY_STACK_MANAGEMENT}${ROUTES.EDIT}/:id`}
              allowedRoles={[ADMIN]}
              component={EditTechnologyStack}
            />
            <AuthRoute
              path={ROUTES.TAG_MANAGEMENT}
              allowedRoles={[ADMIN]}
              component={TagManagement}
              exact
            />
            <AuthRoute
              path={`${ROUTES.TAG_MANAGEMENT}${ROUTES.CREATE}`}
              allowedRoles={[ADMIN]}
              component={CreateTag}
            />
            <AuthRoute
              path={`${ROUTES.TAG_MANAGEMENT}${ROUTES.EDIT}/:id`}
              allowedRoles={[ADMIN]}
              component={EditTag}
            />
            <AuthRoute
              path={ROUTES.TRANSLATIONS}
              allowedRoles={[ADMIN]}
              component={TranslationManagement}
              exact
            />
            <AuthRoute
              path={`${ROUTES.TRANSLATIONS}${ROUTES.EDIT}/:id`}
              allowedRoles={[ADMIN]}
              component={EditTranslation}
            />
            <AuthRoute
              path={ROUTES.USER_PROFILE}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR, CLICK_WORKER]}
              component={UserProfile}
            />
            <AuthRoute
              path={ROUTES.COMPANIES}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              component={Companies}
              exact
            />
            <AuthRoute
              path={`${ROUTES.COMPANIES}${ROUTES.COMPANY_PROFILE}/:id`}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR, CLICK_WORKER]}
              render={({ match: { url } }) => (
                <CompanyProfile>
                  <Route
                    path={`${ROUTES.COMPANIES}${ROUTES.COMPANY_PROFILE}/:id`}
                    component={OverviewCards}
                    exact
                  />
                  <Route
                    path={`${url}${ROUTES.HEATMAP}`}
                    component={Heatmap}
                  />
                  <Route
                    path={`${url}${ROUTES.RECENT_NEWS}`}
                    component={NewsCards}
                  />
                  <Route
                    path={`${url}${ROUTES.SIMILAR_COMPANIES}`}
                    component={SimilarCompanies}
                  />
                  <Route
                    path={`${url}${ROUTES.NOTES}`}
                    component={Notes}
                  />
                </CompanyProfile>
              )}
            />
            <AuthRoute
              path={ROUTES.DEAL_PROCESS}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              render={({ match: { url } }) => (
                <DealProcess>
                  <PropsRoute
                    path={`${url}`}
                    component={Statuses}
                    exact
                  />
                  <PropsRoute
                    path={`${url}${ROUTES.SUCCESS_BAROMETER}`}
                    component={SuccessBarometer}
                    exact
                  />
                </DealProcess>
              )}
            />
            <AuthRoute
              path={ROUTES.UPDATES}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              render={({ match: { url } }) => (
                <Updates>
                  <Route
                    path={`${url}${ROUTES.DEAL_REPORTS}`}
                    component={Reports}
                    exact
                  />
                  <Route
                    path={`${url}`}
                    component={DealTracker}
                    exact
                  />
                  <Route
                    path={`${url}${ROUTES.NEWS}`}
                    component={News}
                    exact
                  />
                  {/* TODO:: Remove this */}
                  {/* <Route
                            path={`${url}${ROUTES.TECHNOLOGY_TRENDS}`}
                            component={TechnologyTrends}
                            exact
                        /> */}

                </Updates>
              )}
            />
            <AuthRoute
              path={ROUTES.PORTFOLIO_MANAGEMENT}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              render={({ match: { url } }) => (
                <PortfolioManagement>
                  <Route
                    path={`${url}${ROUTES.OWN_PORTFOLIO}`}
                    component={OwnPortfolio}
                    exact
                  />
                  <Route
                    path={url}
                    render={({ match: { url } }) => (
                      <PlatformOverview>
                        <Route
                          path={`${url}`}
                          component={Platforms}
                          exact
                        />
                        <Route
                          path={`${url}${ROUTES.PLATFORM_OVERVIEW}/:id`}
                          component={PlatformCompanies}
                        />
                      </PlatformOverview>
                    )}
                  />
                </PortfolioManagement>
              )}
            />
            <AuthRoute
              path={ROUTES.COMPARISON_TOOLS}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              render={({ match: { url } }) => (
                <ComparisonHeatmap>
                  <Route
                    path={url}
                    component={ComparisonTools}
                    exact
                  />
                  <Route
                    path={`${url}${ROUTES.COMPANY_ANALYSOR}`}
                    component={CompetitorAnalysis}
                    exact
                  />
                </ComparisonHeatmap>
              )}
            />
            {/* TODO: Check if exist single news view */}
            <AuthRoute
              path={`${ROUTES.NEWS}/:id`}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              component={SingleNews}
            />
            <Route
              path={ROUTES.PAYPAL_SUCCESS}
              component={PaypalSuccess}
            />
            <Route
              path={ROUTES.PAYPAL_ERROR}
              component={PaypalError}
            />
            <AuthRoute
              path={ROUTES.JOB_ROLE_MANAGEMENT}
              allowedRoles={[ADMIN]}
              render={({ match: { url } }) => (
                <JobRoleManagement>
                  <Route
                    path={`${url}${ROUTES.CREATE}`}
                    component={CreateJobRole}
                  />
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id`}
                    component={EditJobRole}
                  />
                </JobRoleManagement>
              )}
            />
            <AuthRoute
              path={ROUTES.CLICKWORKER_PROFILE}
              allowedRoles={[ADMIN, CLICK_WORKER]}
              component={ClickworkerProfile}
            />
            <AuthRoute
              path={`${ROUTES.CLICKWORKER_SEARCHES}/:userId`}
              allowedRoles={[ADMIN, CLICK_WORKER]}
              component={ClickworkerSearches}
            />
            <AuthRoute
              path={`${ROUTES.CLICKWORKER_COMPANIES}/:userId/:searchId`}
              allowedRoles={[ADMIN, CLICK_WORKER]}
              render={({ match: { url } }) => (
                <ClickworkerCompanies>
                  <Route
                    path={`${url}${ROUTES.EDIT}/:id/:currentTab`}
                    component={EditCompany}
                  />
                </ClickworkerCompanies>
              )}
            />
            <AuthRoute
              path={ROUTES.INVITATIONS}
              allowedRoles={[ADMIN, INVESTOR, ENTREPRENEUR]}
              render={({ match: { url } }) => (
                <Invitations>
                  <Route
                    path={url}
                    component={InvitationsReceived}
                    exact
                  />
                  <Route
                    path={`${url}${ROUTES.INVITATIONS_SENT}`}
                    component={InvitationsSent}
                    exact
                  />
                </Invitations>
              )}
            />
            <Redirect to={ROUTES.HOME} />
          </Switch>
        </Fragment>
      </I18n>
    </Router>
  )
}

export default App
