import React from 'react'
import {Link} from 'react-router-dom'
import ROUTES from '../../../constants/routes'
import Icon from '../../Icon'
import LanguagePicker from 'screens/static/shared/LanguagePicker'
import AvatarDropdown from './AvatarDropdown'

const items = [
    {
        to: `${ROUTES.SAVED_PROFILES}${ROUTES.SAVED_COMPANIES}`,
        icon: 'star'
    }
]

const SavedLinks = () => {

    return (
        <div className='m-topnav__saved'>
            <LanguagePicker />
            {items.map((item, index) => <Link to={item.to} key={index}><Icon name={item.icon} color='#001234'/></Link>)}
            <AvatarDropdown />

        </div>
    )
}

export default SavedLinks