/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import { CurrentUserContext } from 'context/CurrentUserContext'
import { clearStorage } from 'services/localStorage.service'
import ROUTES from 'constants/routes'

import Avatar from 'components/Avatar'
import Icon, { ICON_SIZE } from 'components/Icon'

const AvatarDropdown = ({
    open,
}) => {
    const t = useTranslate()
    const { currentUser, setCurrentUser, isFreemiumUser, isClickworker, isAdmin } = useContext(CurrentUserContext)

    const handleLogout = () => {
        setCurrentUser(null)
        clearStorage()
    }

    if (!currentUser) return null

    return (
        <div className='m-avatar'>
            <Avatar firstName={currentUser.firstName} lastName={currentUser.lastName} avatar={currentUser.avatarPath} role={currentUser?.userRoles && currentUser?.userRoles[0].name} />
            <i className={`a-chevron ${open ? '-up' : '-down'}`} />
            <div className={`m-avatar__popup ${open ? '-open' : ''}`}>
                {isFreemiumUser && !isAdmin && !isClickworker && <Link className='m-avatar__item -upgrade' to={ROUTES.PREMIUM}>{t('general.upgradeToPremium')}</Link>}
                <Link className='m-avatar__item' to={ROUTES.USER_PROFILE}><Icon name='user' size={ICON_SIZE.SMALL} /> {t('general.account')}</Link>
                {/*<Link className='m-avatar__item' to='#'><Icon name='settings' size={ICON_SIZE.SMALL} /> {t('general.settings')}</Link>*/}
                {/*<Link className='m-avatar__item' to='#'><Icon name='card-id' size={ICON_SIZE.SMALL} /> {t('general.yourPublicProfile')}</Link>*/}
                {/*<Link className='m-avatar__item' to='#'><Icon name='building' size={ICON_SIZE.SMALL} /> {t('general.yourCompanies')}</Link>*/}
                <Link className='m-avatar__item -logout' to='#' onClick={handleLogout}>{t('general.logout')}</Link>
            </div>
        </div >
    )
}

AvatarDropdown.propTypes = {
    open: PropTypes.bool.isRequired
}

export default clickOutsideHOC(AvatarDropdown)