import { useLocation } from 'react-router-dom'

const useQueryParams = () => {
    const searchParams = new URLSearchParams(useLocation().search)

    let queryParams = {}
    for (var key of searchParams.keys()) {
        if (key === 'Keyword') {
            const keywordParams = searchParams.get(key).slice(1, -1).split(',').filter(item => item.trim()).filter(item => item !== 'null')
            let encodedKeyboardParams = keywordParams.map(param => {
                return encodeURIComponent(param)
            }).filter(item => item !== null)
            const joinedParams = encodedKeyboardParams.join(',')

            queryParams[key] = `[${joinedParams}]`;
        } else if (key === 'search') {
            const encodedKeywordParams = encodeURIComponent(searchParams.get(key))

            queryParams[key] = encodedKeywordParams;
        }
        else {
            queryParams[key] = searchParams.get(key);
        }

    }
    return queryParams
}

export default useQueryParams;