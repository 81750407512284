import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import ROUTES from 'constants/routes';
import { logo } from 'constants/images';

const GeneralTermsDE = () => {
  return (
    <Fragment>
        <Link to="/"><img src={logo} alt="logo"/></Link>
        <h4>Nutzungsbedingungen für die Web-Applikation (Applikation) der roadX AG, Bellerivematte 10 in 6006 Luzern, Schweiz</h4>
        <div className='m-generalTerms__parts'>
            <span> I Betreiber</span>
            <p>roadX AG (nachfolgend „roadX“) ist der Betreiber einer Web-Applikation, die über folgende Domains erreicht werden kann: <a href='https://www.roadx.tech' target='_blank' >www.roadx.tech</a>, <a href='https://www.roadx.app' target='_blank'>www.roadx.app</a>, <a href='https://www.roadx.ch' target='_blank'>www.roadx.ch</a> Die Applikation ist in deutscher und englischer Sprache angelegt.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span> II Grundlagen der Nutzung</span>
            <p>In der Applikation werden Marktdaten in Verbindung mit Prozessen und Tools zur Unterstützung von Sourcing Strategien (buy-side-Strategien) mit den Schwerpunkten Marktscreening und Deal Pipeline Management bereitgestellt.
              Die Applikation richtet sich in Version 1 an Beteiligungsgesellschaften (Private Equities), Family Offices und Strategische Investoren. Anwender (User) können z. B. sein: Geschäftsführer und Vorstände von Organisationen; M&A Manager, Investment Manager, Analysten und Portfoliomanager, jeweils in Ihrer Funktion als für eine Organisation handelnde Person. Gleiches gilt für Inhaber von Unternehmensanteilen und Berater der vorgenannten Gruppen. Wer die Applikation nutzt, wird nachfolgend als „Nutzer“ bezeichnet.
              Das Angebot von roadX richtet sich ausschliesslich an Unternehmer. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung Ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.
              Das Angebot von roadX richtet sich nicht an Verbraucher. Verbraucher sind solche Personen, die ein Rechtsgeschäft zu Zwecken abschliessen, die überwiegend weder Ihrer gewerblichen noch Ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span> III Zustandekommen des Nutzungsvertrages / Beendigung</span>
            <p>Diese Nutzungsbedingungen regeln die vertraglichen Beziehungen zwischen roadX und dem Nutzer im Rahmen der Nutzung der Applikation.
                Allgemeine Geschäftsbedingungen des Nutzers werden auch dann nicht Vertragsbestandteil, wenn roadX deren Einbeziehung nicht ausdrücklich widerspricht.
              In dem der Nutzer alle Pflichtfelder des Registrierungsformulars ausfüllt und das Formular an roadX absendet macht der Nutzer roadX ein Angebot zum Abschluss eines Vertrages auf Grundlage dieser Nutzungsbedingungen. Ein Angebot des Nutzers kann auch mündlich, telefonisch oder schriftlich erfolgen, wenn roadX dieses Angebotsform im Einzelfall akzeptiert.
              Der Eingang des Angebots stellt noch keine Annahme dar. Der Vertrag kommt erst zustande, wenn der Nutzer seinen persönlichen Account (Nutzerkonto) von roadX freigeschaltet bekommt.
              Ein Anspruch auf Freischaltung eines Nutzerkonto besteht nicht. roadX behält sich die Prüfung vor, ob sie einen Nutzeraccount freischaltet.
              roadX behält sich das Recht vor, Nutzerkonten bei schweren oder wiederholten Verstössen gegen diese Nutzungsbedingungen temporär oder dauerhaft zu sperren. Weitere gesetzliche und vertragliche Rechte und Ansprüche von roadX bleiben unberührt.
              Ein schwerer Verstoss liegt insbesondere vor, wenn konkrete Anhaltspunkte dahingehend vorliegen, dass ein Nutzer falsche Angaben gemacht hat oder die Applikation entgegen dieser Nutzungsbedingungen nutzt oder seiner Zahlungspflicht nicht nachkommt.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span> IV Leistungsbeschreibung</span>
            <p>Nutzer können in der Applikation Ihre Suchprofile hinterlegen. Im Hintergrund gleicht die Datenbank der Applikation ab, ob es passende Treffer („Matches“) gibt. 
              Diese „Matches“ können vom Nutzer in Suchprofilen gespeichert und mit Kommentaren versehen werden. Sie können ausserdem bewertet und miteinander verglichen werden. 
              Zusätzlich werden dem Nutzer ausgewählte Tech-Monitore angeboten, über die globale Marktdaten zu bestimmten Technologien, Herstellern und Anwendungsfeldern angezeigt werden. </p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>V Vergütung</span>
            <p>roadX bietet zwei (2) Vergütungsmodelle an. Diese heissen „Freemium“ und „Professional“. Die Leistungsbilder der beiden Vergütungsmodelle sind hier in der Vergütungstabelle beschrieben: <Link to={ROUTES.PRICING_PAGE}>www.roadx.app/pricing.</Link> <br></br>
              roadX behält sich das Recht vor, die Vergütungsmodelle und die Preisgestaltung mit Wirkung für die Zukunft zu ändern. Soweit nicht abweichend in Textform zwischen roadX und dem Nutzer vereinbart, ist die Vergütung fällig mit Nutzung der Applikation.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>VI Untersagtes Verhalten / Auskunftsanspruch / Schadensersatz</span>
            <p>Der Nutzer hat sämtliche Angaben, die er auf der Applikation hinterlegt, wahrheitsgemäss einzupflegen. roadX behält sich vor, Daten des Nutzers, bei denen der Verdacht eines Verstosses gegen die Wahrheitspflicht besteht, zu löschen.
              Dem Nutzer sind sämtliche Handlungen untersagt, die die Funktionsfähigkeit oder die Integrität der Applikation gefährden könnten. Insbesondere darf die Applikation nicht für Spamming, unberechtigte Manipulationen von Daten und / oder Cyberattacken genutzt werden.
              Dem Nutzer ist es untersagt, Daten und / oder sonstige Inhalte einzustellen, die Rechtsvorschriften oder Rechte Dritter verletzen.
              Dem Nutzer ist es untersagt, die auf der Applikation von roadX aufbereiten Informationen und Daten, die für Nutzer zugänglich sind oder sonst dem Nutzer von roadX zur Verfügung gestellt wurden, an Dritte weiterzugeben. Dies gilt insbesondere für personen- und unternehmensspezifische Daten, die dem Nutzer über die roadX Applikation zur Verfügung gestellt werden. Dritte sind insbesondere auch mit dem Nutzer direkt oder indirekt verbundene Unternehmen. Dritte sind nicht Angestellte und vom Investor offiziell für das Marktscreening und Deal Pipeline Management beauftragte Berater.
              Wenn der Nutzer Informationen oder Daten im Sinne vorstehender Ziffer an Dritte weitergibt, ist roadX berechtigt, vom Nutzer eine Vertragsstrafe in Höhe von zehntausend (10.000,00) EUR zu verlangen. Sowohl roadX als auch dem Nutzer bleibt der Nachweis unbenommen, dass ein höherer oder niedrigerer Schaden entstanden ist.
              Der Nutzer stellt roadX von allen Schäden, Ansprüchen Dritter, Auslagen und Kosten (einschliesslich marktüblicher, nicht auf die gesetzlichen Gebühren beschränkter Anwaltshonorare) frei, die roadX wegen eines Verstosses des Nutzers gegen Vorschriften aus dieser Nutzungsvereinbarung entstehen. Weitere vertragliche und gesetzliche Rechte und Ansprüche von roadX bleiben unberührt.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>VII Rechenzentrum / Datenschutz</span>
            <p>roadX betreibt seine Applikation im Rechenzentrum der Nine Internet Solutions AG, Badenerstrasse 47, 8004 Zürich. 
              Zwischen dem Dienstleister und roadX wurden angemessene Vereinbarungen zur Datensicherheit und zum Datenschutz abgeschlossen.</p>
        </div>
         <div className='m-generalTerms__parts'>
            <span>VIII Haftung</span>
            <p>roadX verpflichtet sich gegenüber dem Nutzer, alle ihm unmittelbar zur Kenntnis gelangten Informationen streng vertraulich zu behandeln und sie nicht an unberechtigte Personen auszuhändigen, weiterzuleiten oder auf sonstige Weise zugänglich zu machen. 
              Die auf der Applikation von roadX dargestellten Unternehmens-, Profil- und Projektdaten werden zum Teil auch von den jeweiligen Nutzern zur Verfügung gestellt oder ergänzt. 
              roadX haftet nicht für die Richtigkeit und Vollständigkeit der auf der Applikation dargestellten Unternehmens- und Projektdaten. 
              roadX haftet nicht für die Richtigkeit der Angaben auf der Applikation. roadX sichert aber zu, die auf der Applikation bereitgestellten Daten nach bestem Gewissen geprüft zu haben. 
              roadX haftet unbeschränkt für Vorsatz und grobe Fahrlässigkeit, sowie bei Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, bei Verletzung einer – ausdrücklich als solche zu bezeichnenden – Garantie, sowie nach dem Produkthaftungsgesetz.
              In Fällen leichter Fahrlässigkeit haftet roadX nur bei Verletzung einer wesentlichen Vertragspflicht. Eine wesentliche Vertragspflicht ist eine Pflicht, deren Erfüllung die Durchführung des Vertrages erst ermöglicht und auf deren Erfüllung sich der Nutzer deswegen regelmässig verlassen darf.
              Die Haftung von roadX ist auf den im Zeitpunkt des Vertragsschlusses vertragstypischen und vorhersehbaren Schadens begrenzt.
              Die Haftungsbeschränkungen gelten entsprechend zugunsten der gesetzlichen Vertreter, Mitarbeiter, Beauftragten, Erfüllungs- und Verrichtungsgehilfen von roadX.
              Der Nutzer erkennt an, dass roadX eine permanente Verfügbarkeit der Applikation nicht gewährleisten kann. Es besteht somit kein Anspruch auf eine permanente Verfügbarkeit. roadX ist bemüht, eine Verfügbarkeit von fünfundneunzig (95) % im Jahresmittel (24 x365) bereitzustellen.</p>
        </div>
          <div className='m-generalTerms__parts'>
            <span>IX Sonstiges</span>
            <p>roadX bemüht sich eine möglichst reibungslose und durchgehende Verfügbarkeit der Applikation zu gewährleisten
              Da roadX nicht an Verbraucher leistet, ist roadX nicht dazu verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
              roadX ist berechtigt, diese Nutzungsbedingungen zu ändern. 
              Nutzer werden einen (1) Monat vor beabsichtigter Änderung der Nutzungsbedingungen über die im Nutzerprofil hinterlegte E-Mail-Adresse über die Änderung informiert. Widerspricht der Nutzer nicht innerhalb von zehn (10) Tagen nach Zugang der Nachricht über die Änderung der Nutzungsbedingungen, so gelten die neuen Nutzungsbedingungen als vom Nutzer akzeptiert.
              Dieser Änderungsmechanismus gilt nicht für Änderungen der Hauptleistungspflichten der Parteien.
              Es gilt das Recht der Schweiz unter Ausschluss des UN-Kaufrechts.
              Ausschliesslicher Gerichtsstand für sämtliche Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ist Luzern.
              Sollten Bestimmungen dieser Nutzungsbedingungen unwirksam sein, so bleiben die übrigen Regelungen wirksam. Die Parteien werden die unwirksame Bestimmung durch eine solche ersetzen, die dem Willen der Parteien wirtschaftlich am nächsten kommt. Dies gilt entsprechend bei Regelungslücken.</p>
        </div>
        {/* <div className="m-generalTerms__table">
            <span>roadX – Vergütungsmodell</span>
            <p>Die Webapplikation umfasst in Version 1.0 die Vergütungsmodelle „Freemium“ und „Professional“. </p>
            <table >
              <TableHeader
                headerItems={TABLE_HEADER}
              />
              <TableBody
                headerItems={TABLE_HEADER}
                data={TABLE_DATA}
              />
            </table>
        </div> */}
    </Fragment>
  )
}

export default GeneralTermsDE