import React from 'react'
import { Link } from 'react-router-dom'
import LogoUrl from 'assets/images/roadx-Logo-white.png'
import ROUTES from 'constants/routes'

const Logo = () => {
    return (
        <Link to={ROUTES.HOME}>
            <img src={LogoUrl} className='a-logo' />
        </Link>
    )
}

export default Logo
