import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import { AlertContext } from 'context/AlertContext'

import axios, { GET } from '../utils/axiosClient'
import { formatResponseData, formatUrl } from '../utils/jsonApiFormatters'
import { ALERT_TYPES } from 'components/Alert'
import ROUTES from 'constants/routes'

const useFetchDataById = (entity, id, params, reload) => {

    const history = useHistory()
    const { setAlert } = useContext(AlertContext) || {}

    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                let PATH = `/${entity}/${id}`
                const url = formatUrl(PATH, params)
                const response = await axios(GET, url)
                const { data } = formatResponseData(response.data)
                setData(data)
                setIsLoading(false)
                setError(null)
            } catch (error) {
                setData(null)
                setIsLoading(false)
                setError(error)
                if (error?.response?.status !== 403) {
                    setAlert(error, ALERT_TYPES.ERROR)
                }
            }
        }
        if(id) {
            getData()
        }
    }, [id, reload])

    return {
        data,
        isLoading,
        error
    }
}

export default useFetchDataById
