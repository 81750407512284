import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'

import ROUTES from 'constants/routes';
import { logo } from 'constants/images';

const GeneralTermsEN = () => {

  return (
    <Fragment>
        <Link to="/"><img src={logo} alt="logo"/></Link>
        <h4>Terms of Use for the Web Application ("Application") of roadX AG, Bellerivematte 10 in 6006 Luzern, Switzerland </h4>
        <div className='m-generalTerms__parts'>
            <span> I Operator</span>
            <p>roadX AG (hereinafter referred to as "roadX") is the operator of a web application ("Application") that can be reached via the following domains: <a href='https://www.roadx.tech' target='_blank' >www.roadx.tech</a>, <a href='https://www.roadx.app' target='_blank'>www.roadx.app</a>, <a href='https://www.roadx.ch' target='_blank'>www.roadx.ch</a> The Application is available in German and English.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span> II Basis of Use</span>
            <p>The Application provides market data in connection with processes and tools to support sourcing strategies (buy side strategies) with the focus on market screening and deal pipeline management.
                Version 1 of the Application is directed towards private equities, family offices and strategic investors. Users may be managing directors and executive board members of organisations, M&A managers, investment managers, analysts and portfolio managers in the capacity in which they act on behalf of an organisation. The same applies to owners of company shares and consultants of the aforementioned groups. Those who use the Application are hereinafter referred to as "Users".
                The offering of roadX is intended exclusively for entrepreneurs. An entrepreneur is any natural or legal person or incorporated partnership that concludes a legal transaction in exercise of its self-employed or commercial activity.
                The offering of roadX is not intended for consumers. Consumers are persons who conclude a legal transaction for purposes that are largely unrelated to their self-employed or commercial activity.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span> III Coming into Force/Termination of the Usage Agreement</span>
            <p>These Terms of Use govern the contractual relationship between roadX and the User with respect to the use of the Application.
                General terms and conditions of the user shall not become part of the agreement, even if roadX does not expressly object to their inclusion.
                By completing all mandatory fields of the registration form and submitting the form to roadX, the User offers roadX the conclusion of an agreement on the basis of these Terms of Use. The User's offer may also be made orally, by telephone or in writing, in the event roadX accepts this offer form in individual cases.
                The receipt of the offer does not in itself constitute an acceptance. The agreement shall only come into existence once roadX activates the User's personal account (User account).
                Activation of a User account cannot be demanded. roadX reserves the right to consider whether or not to activate a User account.
                roadX reserves the right to temporarily or permanently block User accounts in the event of serious or repeated breaches of these Terms of Use. Further statutory and contractual rights and claims of roadX remain unaffected.
                A serious breach will be deemed to exist especially if there are clear indications that a User has provided false information or is using the Application in violation of these Terms of Use or does not comply with their payment obligations.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span> IV Description of Services</span>
            <p>Users can store their search profiles in the Application. In the background, the database of the Application checks whether there are any matches. 
                The User can store these matches in search profiles and enter comments for them. Moreover, they can be rated and compared with each other. 
                The User is additionally offered selected tech monitors, by means of which global market data on certain technologies, vendors and application fields are displayed. </p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>V Fee</span>
            <p>roadX offers two (2) fee models. These models are called "Freemium" and "Professional". The performance profiles of the two fee models are described in the fee table here:  <Link to={ROUTES.PRICING_PAGE}>www.roadx.app/pricing.</Link> <br></br>
              roadX reserves the right to amend the fee models and the pricing with effect for the future. Unless agreed otherwise in text form between roadX and the User, the fee shall be due upon use of the Application.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>VI Prohibited Behaviour, Right to Information, Damages</span>
            <p>The User shall truthfully enter all details that he stores in the Application. roadX reserves the right to delete any User data suspected of breaching the truthfulness obligation.
                The User shall refrain from any and all actions that could endanger the operability or integrity of the Application. In particular, the Application shall not be used for spamming, unauthorised data manipulation and/or cyber-attacks.
                The User shall not upload any data and/or other content that breach legal regulations or third-party rights.
                The User is prohibited from sharing the information and data processed in the Application of roadX, which are accessible to the User or which roadX has otherwise made available to the user, with third parties. This includes, but is not limited, to personal and company-specific data that are made available to the User via the roadX Application. The term "third parties" expressly also includes companies directly or indirectly affiliated with the User. The term "third parties" does not include employees and consultants officially commissioned by the User for the market screening and deal pipeline management.
                If the User shares information or data in the sense of the above subsection with third parties, roadX may demand a penalty in the amount of ten thousand euros (€10,000) from the User. Both roadX and the User are free to furnish evidence that the damage was higher or lower.
                The User indemnifies roadX against all damage, third-party claims, expenses and costs (including lawyers fees that are usual on the market; this is not limited to statutory fees), that roadX incurs due to the User's breach of regulations from these Terms of Use. Further contractual and statutory rights and claims of roadX remain unaffected.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>VII Data Centre / Data Protection</span>
            <p>roadX operates its Application in the data centre of Nine Internet Solutions AG, Badenerstrasse 47, 8004 Zürich, Switzerland.  Adequate data security and data protection agreements have been concluded between the service provider and roadX.</p>
        </div>
         <div className='m-generalTerms__parts'>
            <span>VIII Liability</span>
            <p>roadX undertakes with regard to the User to treat all information it directly learns of as strictly confidential and not to hand this over, forward it or otherwise make it accessible to unauthorised persons. 
                Some of the company, profile and project data presented in the Application of roadX are also made available or supplemented by the respective Users. 
                roadX shall not be liable for the correctness and completeness of the company and project data presented in the Application. 
                roadX shall not be liable for the correctness of the information in the Application. However, roadX confirms that it has checked the data made available in the Application to the best of its knowledge and belief. 
                roadX shall be fully liable for intent and gross negligence in the event of damage from injury to life, body and health, in the event of a breach of a guarantee expressly designated as such or pursuant to the Swiss Product Liability Act (PLA).
                In cases of slight negligence, roadX shall only be liable in the event of a breach of a material contractual obligation. A material contractual obligation is an obligation whose fulfilment is essential to the performance of the agreement and on whose fulfilment the User may thus always rely.
                The liability of roadX is limited to the damage that, as of the conclusion of the agreement, is foreseeable and typical for the Agreement.
                By analogy, the disclaimer also applies to the benefit of legal representatives, employees, appointees and agents of roadX.
                The User acknowledges that roadX cannot guarantee permanent availability of the Application. Permanent availability can thus not be demanded. Nevertheless, roadX endeavours to ensure an average availability of ninety-five percent (95%) a year (24 x 365).</p>
        </div>
          <div className='m-generalTerms__parts'>
            <span>IX Miscellaneous</span>
            <p>roadX will do its best to ensure smooth, ongoing availability of the Application.
              As roadX does not service consumers, roadX is under no obligation to participate in arbitration proceedings of a consumer arbitration board. roadX may amend these Terms of Use. 
              Users will be informed of any intended amendments to the Terms of Use one (1) month in advance via the e-mail address stored in the User profile. If the User does not raise any objections within ten (10) days of the receipt of the notification about the amendment to the Terms of Use, the new Terms of Use will be deemed accepted by the User.
              This amendment mechanism shall not apply to amendments to the Parties' cardinal obligations.
              The laws of Switzerland shall apply, under exclusion of the UN Convention on Contracts for the International Sale of Goods (CISG).
              The courts of Lucerne, Switzerland, shall have exclusive jurisdiction over all disputes arising from or in connection with these Terms of Use.
              The contract language is German. The English language version is for information only.
              Should any provisions of these Terms of Use be invalid, the other regulations shall remain valid. The Parties shall replace the invalid provision with a provision whose economic effect comes as close as possible to what is intended by the Parties. By way of analogy, this shall also apply in the case of any missing regulations.</p>
        </div>
        {/* <div className="m-generalTerms__table">
            <span>roadX – Vergütungsmodell</span>
            <p>Die Webapplikation umfasst in Version 1.0 die Vergütungsmodelle „Freemium“ und „Professional“. </p>
            <table >
              <TableHeader
                headerItems={TABLE_HEADER}
              />
              <TableBody
                headerItems={TABLE_HEADER}
                data={TABLE_DATA}
              />
            </table>
        </div> */}
    </Fragment>
  )
}

export default GeneralTermsEN