import React, { useEffect, useState } from 'react'

import useFetchDataById from 'hooks/useFetchDataById'
import { getUserId } from 'services/localStorage.service'
import ENTITY from 'constants/entities'
import { ROLES } from 'constants/enums'

export const CurrentUserContext = React.createContext()

const CurrentUserContextProvider = props => {
    const [currentUser, setCurrentUser] = useState(null)
    const [isFreemiumUser, setIsFreemiumUser] = useState(true)
    const [isClickworker, setIsClickworker] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    const userId = getUserId()
    const { data, isLoading } = useFetchDataById(ENTITY.USER, userId, { include: 'userRoles,language,avatar,jobRole,package,privateEquities,company,country,billingCountry' })

    useEffect(() => {
        setCurrentUser(data)
    }, [data])

    useEffect(() => {
        if (currentUser && currentUser.package && currentUser.package.id !== 1) {
            setIsFreemiumUser(false)
        } else {
            setIsFreemiumUser(true)
        }
        if (currentUser && currentUser?.userRoles.some(role => role.name === ROLES.CLICK_WORKER.NAME) && !currentUser?.userRoles.some(role => role.name === ROLES.ADMIN.NAME)) {
            setIsClickworker(true)
            setIsFreemiumUser(false) //if it is clickworker do not check package
        } else {
            setIsClickworker(false)
        }
        if (currentUser && currentUser?.userRoles.some(role => role.name === ROLES.ADMIN.NAME)) {
            setIsAdmin(true)
            setIsFreemiumUser(false) //if it is admin do not check package
        } else {
            setIsAdmin(false)
        }
    
    }, [currentUser])

    return (
        <CurrentUserContext.Provider value={{
            currentUser,
            isLoading,
            setCurrentUser,
            userId,
            isFreemiumUser,
            isClickworker,
            isAdmin
        }}>
            {props.children}
        </CurrentUserContext.Provider>
    )
}

export default CurrentUserContextProvider