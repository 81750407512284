import React, { useContext } from 'react'

import { TranslationContext } from 'context/TranslationContext';

import GeneralTermsDE from './GeneralTermsConditionsDE';
import GeneralTermsEN from './GeneralTermsConditionsEN';
import FAQ from 'screens/noAuth/FAQ';
import Footer from 'components/Layout/Footer';

const GeneralTerms = () => {

  const { translations: { locale } } = useContext(TranslationContext)

  return (
    <div className='m-generalTerms'>
      {locale === 'de' ? <GeneralTermsDE /> : <GeneralTermsEN />}
      <FAQ/>
      <Footer />
    </div>
  )
}

export default GeneralTerms