import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

const Questions = () => {
    const ENContent = () => {
        return (
            <Fragment>
                <h3>5 reasons for roadX</h3>
                <p>Experience how you as a professional investor...</p>
                <ul>
                    <li> prevent your deal teams from spending several weeks/months finding the right targets and instead <span>get a first, well-founded target list in just a few minutes.</span></li>
                    <li><span>recognize important backgrounds </span> not only in the deal process, but <span>already before the first contact with a target. </span></li>
                    <li>no longer be surprised that companies have already been sold, because you can <span> see current transactions more quickly.</span></li>
                    <li>can match your strategy with potential targets by <span>receiving information on platforms and portfolios - including those of competitors - with just a few clicks</span></li>
                    <li>reduce the effort required to maintain your deal pipeline by <span>benefiting from regular updates on targets and managing relevant information arranged in one place. </span></li>
                </ul>
            </Fragment>
        )
    }
        const DEContent = () => {
        return (
            <Fragment>
                <h3>5 Gründe für roadX</h3>
                <p>Erleben Sie, wie Sie als professioneller Investor…</p>
                <ul>
                    <li>verhindern, dass Ihre Deal-Teams mehrere Wochen/Monate im Finden der richtigen Targets verbringen und stattdessen eine <span>erste, fundierte Target-Liste bereits in wenigen Minuten</span> erhalten.</li>
                    <li><span>wichtige Zusammenhänge</span> nicht erst im Deal-Prozess <span>erkennen</span>, sondern <span>bereits vor dem Erstkontakt mit einem Target.</span></li>
                    <li>nicht mehr überrascht werden, dass Unternehmen bereits verkauft sind, da Sie <span>aktuelle Transaktionen schneller mitbekommen.</span></li>
                    <li> Ihre Strategie mit potentiellen Targets abgleichen können, in dem Sie <span>per Knopfdruck Informationen zu Plattformen und Portfolien - auch von Wettbewerbern - erhalten.</span></li>
                    <li> den Aufwand in der Pflege Ihrer Deal-Pipeline reduzieren, in dem Sie <span>von regelmässigen Aktualisierungen von Targets profitieren und relevante Informationen übersichtlich an einem Ort</span> verwalten.</li>
                </ul>
            </Fragment>
        )
    }
    return (
        <Fragment>
            {localStorage.getItem('evolution_user_language_code') === 'en' ? <ENContent /> : <DEContent />}
        </Fragment>
       
    )
}

export default Questions