export default {
    USER: 'users',
    ROLE: 'roles',
    SUBSCRIBER: 'subscribers',
    FILE: 'files',
    TRANSLATION: 'translations',
    LANGUAGE: 'languages',
    FILTER: "filters",
    FILTER_CATEGORY: "filter_categories",
    FILTER_ITEM: "filter_items",
    FILTER_ITEM_TYPE: "filter_item_types",
    FILTER_SELECTION_TYPES: "filter_selection_types",
    FILTER_VALUE_TYPES: "filter_value_types",
    ENTITY: "entities",
    ENTITY_RELATIONSHIP: "entity_relationship",
    TAG: "tags",
    TECHNOLOGY_STACKS: "technology_stacks",
    COMPANY: "companies",
    SAVED_COMPANY: "saved_companies",
    USER_SAVED_COMPANY: "user_saved_companies",
    COMPANY_LOCATION: "company_locations",
    COMPANY_EMPLOYEE: "company_employees",
    SAVED_SEARCH: "saved_searches",
    USER_SAVED_SEARCH: "user_saved_search",
    SELECTION_PROCESS: "selection_processes",
    SELECTION_PROCESS_TYPE: "selection_process_types",
    SELECTION_PROCESS_SUGGESTION: "selection_process_suggestions",
    SELECTION_PROCESS_ANSWER: "selection_process_answers",
    DEAL_PROCESS: "deal_processes",
    DEAL_PROCESS_FILTERED: "deal_process_filtered",
    DEAL_PROCESS_TYPE: "deal_process_types",
    INDUSTRY: "industries",
    GET_COMPANY_ACTIVITIES: "get_company_activities",
    COMPANY_PINS: "company_pins",
    COMPANY_NOTE: "company_notes",
    USER_COMPANY_NOTE: "user_company_notes",
    COMPANY_FILTER: "companies_filtered",
    COMPANY_PER_INDUSTRY: "activities_statistic",
    SEARCH_PROFILE_MATCHES: "search_profile_matches",
    USER_PREFERENCE: "user_preferences",
    PACKAGE: "packages",
    PACKAGE_PERIOD_TYPE: "package_period_types",
    PACKAGE_PLAN: "package_plans",
    PAYMENT_METHOD: "payment_methods",
    PAYMENT: "payment",
    USER_SEARCH_STATISTIC: "user_search_statistic",
    NEWS_CATEGORY: "news_categories",
    NEWS: "news",
    ACQUISITION_NEWS: "acquisition_news",
    VENDOR: "vendors",
    VENDOR_CATEGORY: "vendor_categories",
    PARTNER_TYPE: "partner_types",
    PARTNER_LEVEL: "partner_levels",
    PRIVATE_EQUITIES: "private_equities",
    PLATFORMS: "platforms",
    VENDOR_PARTNERS: "vendor_partners",
    ACTIVITY: "activities",
    MAIN_SOFTWARE_CATEGORY: "main_software_categories",
    MAIN_PORTFOLIO_THEMES: "main_portfolio_themes",
    COMPANY_SOFTWARE_CATEGORY: "company_software_categories",
    COMPANY_PORTFOLIO_THEMES: "company_portfolio_themes",
    COMPANY_INDUSTRY: "company_industries",
    COMPANY_TECHNOLOGY: "company_technologies",
    TECHNOLOGY: "technologies",
    DATA_SOURCE_TYPE: "data_source_types",
    TOP_DOMAINS: "top_domains",
    QUANTITY_OVER_TIME: "quantity_over_time",
    PRODUCT_FREQUENCY: "product_frequencies",
    APPLICATION_FREQUENCY: "aplication_frequencies",
    TECHNOLOGY_FREQUENCY: "technology_frequencies",
    COMPANY_FREQUENCY: "company_frequencies",
    PRODUCT_OVERVIEW: "product_overview",
    HEATMAPS_PEER_APPLICATION : "heatmaps_peer_applications",
    HEATMAPS_PEER_TECHNOLOGY : "heatmaps_peer_technologies",
    HEATMAPS_PRODUCT_TECHNOLOGY : "heatmaps_product_technologies",
    HEATMAPS_PRODUCT_INDUSTRIES : "heatmaps_product_industries",
    HEATMAPS_PEER_INDUSTRIES : "heatmaps_peer_industries",
    COMPANY_INFO: "company_infos",
    COMPANY_PEOPLE: "company_people",
    COMPANY_PERSON_ROLE: "company_person_roles",
    COMPANIES_FILTERED_COUNTER: "companies_filtered_counter",
    SEARCH_PROFILE_MATCHES_STATISTIC: "search_profile_matches_statistic",
    COMPARED_COMPANY: "compared_companies",
    COMPARE_TYPE: "compare_types",
    SAVED_COMPARED_COMPANY: "saved_compared_companies",
    REMOVE_COMPARED_COMPANY: "remove_compared_companies",
    ADD_COMPARED_COMPANY: "add_all_compared_companies",
    EQUINTEL_SETTINGS: "equintel_settings",
    COMPANY_FINANCIALS: "company_financials",
    JOB_ROLE: "job_roles",
    GENERAL_STATISTIC: "general_statistic",
    COUNTRIES_STATISTIC: "countries_statistic",
    SIMILAR_COMPANIES: "similar_companies",
    COMPANY_STATUS: "company_statuses",
    ACQUISITION_DEAL_TRACKER: "acquisition_deal_trackers",
    USER_COMPANY_STATISTICS: "user_company_statistics",
    COUNTRY: "countries",
    NOTICE_TYPE: "notice_types",
    NOTICE_RECIPIENT: "notice_recipients",
    NOTICE: "notices",
    SAVED_SEARCH_COMPANY: "saved_search_companies",
    COMPANY_MAP_PINS: "company_map_pins",
    STRATEGIC_INVESTOR_TYPES:"strategic_investor_types",
    FEDERATION: "federations",
    COMPANY_FEDERATION:'company_federations',
    PORTFOLIO_THEME: "portfolio_themes",
    USER_PACKAGE_SUBSCRIPTION: "user_package_subscriptions",
    GROUP: 'groups',
    SUBSIDIARY_COMPANY_FOCUS: 'subsidiary_company_focuses',
    CLICK_WORKER_COMPANY: 'click_worker_companies',
    COMPANY_FORMER_BUYER: 'company_former_buyers',
    JOB_ROLE_MANAGEMENT: "job_role_management",
    CLICKWORKER_USER: 'clickworker_users',
    CLICKWORKER_SAVED_SEARCH: 'clickworker_saved_searches',
    CLICKWORKER_COMPANY: 'clickworker_companies',
    PERSON_ROLE: 'person_roles',
    COMPANY_FORMER_NAME: 'company_former_names',
    COMPANY_PROFILE: 'company_profiles',
    INNER_TRENDS_COMPARE_TYPES: 'inner_trends_compare_types',
    INNER_TRENDS_ACQUISITION_NEWS: 'inner_trends_acquisition_news',
    INNER_TRENDS_PERFORMED_SEARCH: 'inner_trends_performed_search',
    SHARE_SAVED_SEARCH: 'share_saved_search',
    MY_SAVED_SEARCHES: 'my_saved_searches',
    SHARED_SAVED_SEARCHES: 'shared_saved_searches',
    COMPANY_VENDOR: 'company_vendors_crud'
}