import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserToken } from 'services/localStorage.service'

import Layout from 'components/Layout'

import ROUTES from 'constants/routes'
import { CurrentUserContext } from "context/CurrentUserContext";

const AuthRoute = ({
    path,
    component,
    exact,
    render,
    allowedRoles,
    children
}) => {

    const token = getUserToken()
    const { currentUser } = useContext(CurrentUserContext)

    if (!token) return <Redirect to={ROUTES.LOGIN} />
    if (!currentUser) return null

    const hasPermission = allowedRoles.some(
        (role) => currentUser.userRoles.some(userRole => userRole.name === role.NAME)
    )

    return hasPermission ? (
        <Layout>
            <Route path={path} component={component} exact={exact} render={render}>
                {children}
            </Route>
        </Layout>
    ) : (
        <Redirect to={ROUTES.HOME} />
    )
}

export default AuthRoute