import React, { Children } from 'react'
import AlertContextProvider from './AlertContext'
import CurrentUserContextProvider from './CurrentUserContext'
import TranslationContextProvider from './TranslationContext'

const AppContext = ({ children }) => {
    return (
        <AlertContextProvider>
            <CurrentUserContextProvider>
                <TranslationContextProvider>
                        {Children.only(children)}
                </TranslationContextProvider>
            </CurrentUserContextProvider>
        </AlertContextProvider>
    )
}

export default AppContext
