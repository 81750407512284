import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { logo } from 'constants/images'

const DataProtectionDE = () => {
  return (
    <Fragment>
        <Link to="/"><img src={logo} alt="logo"/></Link>
        <h4>Datenschutzerklärung für die Web-Applikation der roadX AG, Bellerivematte 10 in 6006 Luzern, Schweiz</h4>
        <p>Mit der nachfolgenden Datenschutzerklärung erhalten Sie die Information darüber, welche Daten wir von Ihnen im Zusammenhang mit der Nutzung unserer roadX Web-Applikation und der darüber abrufbaren Funktionen, Module und Komponenten verarbeiten. </p>
        <div className='m-generalTerms__parts'>
            <h3>Datenschutzerklärung</h3>
        </div>
        <div className='m-generalTerms__parts'>
            <span> 1. Geltungsbereich und Gegenstand der Datenschutzerklärung</span>
            <p>Gegenstand dieser Datenschutzerklärung ist die Information darüber, welche personenbezogenen Daten wir über unsere roadX Web-Applikation erheben und zu welchem Zweck wir diese verarbeiten. Soweit auf andere Seiten verlinkt wird, haben wir weder Einfluss noch Kontrolle auf die verlinkten Inhalte und die dortigen Datenschutzbestimmungen. Wir empfehlen, die Datenschutzerklärungen auf den verlinkten Webseiten zu prüfen, um feststellen zu können, ob und in welchem Umfang personenbezogene Daten erhoben, verarbeitet, genutzt oder Dritten zugänglich gemacht werden.</p>
            <p>Aus Gründen der besseren Lesbarkeit wird auf die differenzierte Verwendung verschiedener Sprachformen verzichtet. Sämtliche Personenbezeichnungen gelten gleichermaßen für alle Geschlechter männlich/weiblich/divers.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span> 2. Kontaktdaten des Verantwortlichen</span>
            <p>roadX AG</p>
            <p>Oliver Wegner, CEO</p>
            <p>Bellerivematte 10</p>
            <p>CH-6006 Luzern</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>3. Kontaktdaten zum Datenschutz</span>
            <p>datenschutz@roadX.tech</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>4. Schutz Ihrer personenbezogenen Daten</span>
            <p>Im Bereich der Verarbeitung Ihrer personenbezogenen Daten halten wir uns an die Anforderungen des Schweizer Bundesgesetz über den Datenschutz (DSG) und die der Verordnung (EU) 2016/679 (Datenschutzgrundverordnung) </p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>5. Erhebung personenbezogene Daten</span>
            <p>Wir erheben bei der Nutzung unserer roadX Web-Applikation die folgenden personenbezogenen Daten:</p>
            <div className='m-generalTerms__parts--list'>
                <span>(1) Zugriffsdaten</span>
                <div>
                    <p>Dies ist erforderlich, damit die roadX Web-Applikation aktuelle Daten (z.B. Warnungen) abrufen oder bestimmte auf dem Endgerät gespeicherte Daten an das Serversystem übermitteln kann. Die Zugriffsdaten umfassen folgende Daten:</p>
                    <ul>
                        <li>IP-Adresse;</li>
                        <li>Datum und Uhrzeit des Abrufs;</li>
                        <li>Datenmenge;</li>
                        <li>Meldung, ob der Datenaustausch erfolgreich war.</li>
                    </ul>
                </div>
            </div>
            <div className='m-generalTerms__parts--list'>
                <span>(2) Bei Nutzung der roadX Web-Applikation</span>
                <div>
                    <p>Bei Nutzung der roadX Web-Applikation erheben wir die nachfolgend beschriebenen personenbezogenen Daten, um Sie im Bereich Ihrer Sourcing Strategien mit den Schwerpunkten Marktscreening und Deal Pipeline Management zu unterstützen:</p>
                    <span>Pflichtdaten:</span>
                    <ul>
                        <li>Vorname und Nachname;</li>
                        <li>E-Mail;</li>
                        <li>Mobilfunknummer und Telefonnummer;</li>
                        <li>Firmenname</li>
                        <li>Firmenadresse (Strasse, Postleitzahl, Ort);</li>
                    </ul>
                    <span>Optionale Daten:</span>
                    <ul>
                        <li>Abteilung und Funktion;</li>
                        <li>Sprache;</li>
                        <li>Portraitfoto;</li>
                    </ul>
                    <p>Rechtsgrundlage für die Verarbeitung dieser personenbezogenen Daten ist Art. 6 Abs. 1 b) DSGVO. Ihre personenbezogenen Daten werden für die zuvor beschriebenen Zwecke nach Ihrer Abmeldung 2 Monate gespeichert und anschließend automatisch gelöscht. Eine Pflicht zur Bereitstellung der zuvor beschriebenen Pflichtdaten besteht nicht. Wenn Sie uns diese personenbezogenen Daten jedoch nicht bereitstellen, können Sie unsere Web-Applikation nicht nutzen.</p>
                </div>
            </div>
             <div className='m-generalTerms__parts--list'>
                <span>(3) Log der roadX Web-Applikation</span>
                <div>
                    <p>Für die Verbesserung der roadX Web-Applikation werden wir das Verhalten von Ihnen pseudonymisiert tracken. Rechtsgrundlage für die Verarbeitung dieser personenbezogenen Daten ist Art. 6 Abs. 1 lit a) DSGVO. Ihre personenbezogenen Daten werden für die zuvor beschriebenen Zwecke gespeichert. Sie haben jederzeit die Möglichkeit Ihre Einwilligung zu widerrufen. Eine Pflicht zur Bereitstellung der zuvor beschriebenen Pflichtdaten besteht nicht, jedoch wenn Sie diese personenbezogenen Daten uns nicht bereitstellen, können Sie unsere Web-Applikation nicht nutzen.</p>
                </div>
            </div>
            <div className='m-generalTerms__parts--list'>
                <span>(4) App Reporting</span>
                <div>
                    <p>Für unsere Produkt Analyse nutzen wir das In-App-Reporting des Dienstleisters InnerTrends Ltd. InnerTrends erhält aufgrund der Dienstleistung keine personenbezogenen Daten von Ihnen und erstellt einen anonymisierten Report für uns. Wir nutzen diese Reports, um das Verhalten von Anwendern in der roadX Web-Applikation zu verstehen und zu analysieren. Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit f) DSGVO. Wir sind der Ansicht, dass aufgrund unserer implementierten Maßnahmen unser Interesse an der Verarbeitung der Daten überwiegt.</p>
                </div>
            </div>
        </div>

        <div className='m-generalTerms__parts'>
            <span>6. Dienstleister</span>
            <p>Wir geben Ihre Daten nicht an Dritte weiter. Im Bereich unserer roadX Web-Applikation arbeiten wir allerdings mit professionellen Dienstleistern zusammen. Grundlage dieses Vertragsverhältnisses ist u. a. die Verpflichtung der Anbieter, besondere Vorkehrungen zum Schutz Ihrer Daten getroffen zu haben und sämtliche personenbezogene Daten nur gemäß unseren Weisungen zu verarbeiten. Außerdem haben alle Dienstleister, wenn notwendig einen Auftragsverarbeitungsvertrag unterschrieben. Nachfolgend finden Sie die Kategorien von Empfängern der personenbezogenen Daten:</p>
            <span>Dienst</span>
            <p>Provider</p>
            <p>Softwareentwickler</p>
            <p>Softwarewartung</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>7.Übermittlung in Drittländer </span>
           <div className='m-generalTerms__parts--list'>
                <span>(1) Server-Standort</span>
                <div>
                    <p>Ihre personenbezogenen Daten werden von uns ausschließlich auf Servern in der Schweiz verarbeitet. roadX betreibt seine Applikation im Rechenzentrum der Nine Internet Solutions AG, Badenerstrasse 47, 8004 Zürich. Zwischen dem Dienstleister und roadX wurden angemessene Vereinbarungen zur Datensicherheit und zum Datenschutz abgeschlossen. Die Schweiz besitzt einen „Angemessenheitsbeschluss“ der Europäischen Kommission gemäß Artikel 45 DSGVO. Dadurch wird festgelegt, dass ein Drittland (d.h. ein Land, das nicht an die DSGVO gebunden ist) oder eine internationale Organisation ein angemessenes Schutzniveau für personenbezogene Daten bietet.</p>
                </div>
            </div>
            <div className='m-generalTerms__parts--list'>
                <span>(2) Wartung/Support</span>
                <div>
                    <p>Im Bereich der Wartung und des Supports unserer roadX Web-Applikation nutzen wir den Dienstleister ewasoft AG, Rathausstrasse 14. 6340 Baar. Dabei können wir eine Übermittlung in Drittländer aufgrund der Dienstleistung durch ewasoft nicht ausschließen. Diese Übermittlung haben wir datenschutzrechtlich mit Hilfe der Standardvertragsklauseln vom Juni 2021 ermöglicht, damit wir die Anforderungen aus Kapitel 5 der DSGVO einhalten und die aus Art. 6 DSG. Außerdem haben wir ein Transfer Impact Assessment (TIA) wie datenschutzrechtlich gefordert durchgeführt.</p>
                </div>
            </div>
        </div>
         <div className='m-generalTerms__parts'>
            <span>8. Plugins und eingebettete Funktionen</span>
            <p>Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern Ihrer jeweiligen Anbieter (nachfolgend bezeichnet als „Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als „Inhalte”).
                Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als „Web Beacons“ bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die „Pixel-Tags“ können Informationen, wie der Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch mit solchen Informationen aus anderen Quellen verbunden werden.</p>
            <div className='m-generalTerms__parts--flex'>
                <div className='m-generalTerms__parts--part'>
                    <span>Anbieter</span>
                    <p>Google Ireland Limited</p>
                    <p>Gordon House, Barrow Street</p>
                    <p>Dublin 4, Irland</p>
                </div>
                 <div className='m-generalTerms__parts--part'>
                    <span>Dienst</span>
                    <p>Google Maps:</p>
                    <p>Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google ein. Zu den verarbeiteten Daten können insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden</p>
                </div>
                 <div className='m-generalTerms__parts--part'>
                    <span>Rechtsgrundlage</span>
                    <p>Art. 6 Abs. 1 lit a DSGVO</p>
                </div>
                <div className='m-generalTerms__parts--part'>
                    <span>Informationen</span>
                    <p>https://www.google.de/maps</p>
                    <p>https://policies.google.com/privacy</p>
                </div>
            </div>
        </div>
        <div className='m-generalTerms__parts'>
            <span>9. Datensicherheit</span>
            <p>Wir unterhalten aktuelle technische Maßnahmen zur Gewährleistung der Datensicherheit, insbesondere zum Schutz Ihrer personenbezogenen Daten vor Gefahren bei Datenübertragungen sowie vor Kenntniserlangung durch Dritte. Diese werden dem aktuellen Stand der Technik entsprechend fortlaufend angepasst und überprüft. Zur Wahrung der Vertraulichkeit und Integrität, der von Ihnen auf unserer Website angegebenen Daten werden, diese Daten via „https“ und Transport Layer Security (TLS) übertragen.</p>
        </div>
        <div className='m-generalTerms__parts'>
            <span>10. Ihre Rechte</span>
            <p>Sie haben das Recht, jederzeit von uns eine Bestätigung zu verlangen, ob wir personenbezogene Daten von Ihnen verarbeiten und das Recht auf Auskunft über diese personenbezogenen Daten. Daneben haben Sie das Recht auf Berichtigung, Löschung und Einschränkung der Datenverarbeitung, sowie das Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten einzulegen, bzw. die Einwilligung zur Datenverarbeitung jederzeit zu widerrufen oder die Datenübertragung zu fordern. Alle Informationswünsche, Auskunftsanfragen, Widerrufe oder Widersprüche zur Datenverarbeitung richten Sie bitte per E-Mail an datenschutz@roadx.tech. Darüber hinaus haben Sie das Recht, sich im Falle von Datenschutzverletzungen bei einer Aufsichtsbehörde zu beschweren.</p>
        </div>
            <div className='m-generalTerms__parts'>
            <span>11. Version</span>
            <p>Dies ist die aktuelle Fassung unserer Datenschutzerklärung (gültig ab dem 14.03.2022). Wir behalten uns vor, diese Datenschutzerklärung anzupassen (insbesondere im Falle von Änderungen der Rechtslage oder Änderungen der Dienste). Aus diesem Grund empfiehlt es sich diese Datenschutzerklärung in regelmäßigen Abständen aufzurufen.</p>
        </div>
    </Fragment>
  )
}

export default DataProtectionDE
